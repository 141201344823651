const getters = {
    // app
    appId:state => state.app.appId,
    channel: state => state.app.channel,
    lang: state => state.app.lang,
    deviceId: state => state.app.deviceId,
    os:state => state.app.os,
    uploadLog: state => state.app.uploadLog,
    test: state => state.app.test ,       //是否测试模式 0-等于正常模式 1-登录测试模式
    gameId: state => state.app.gameId, 
    gameLoginType: state => state.app.gameLoginType,
    version:state => state.app.version,
    records:state => state.app.records,
    miniGame: state => state.app.miniGame,

    token: state => state.user.token,
    username: state => state.user.username,
    password: state => state.user.password,
    lastUsername: state => state.user.lastUsername,
    lastPassword: state => state.user.lastPassword,
    userId: state => state.user.userId,
    isLogin: state => state.user.isLogin,
    needLogin: state => state.user.needLogin,
    email: state => state.user.email,
    fbName: state => state.user.fbName,
    googleName: state => state.user.googleName,
    twitterName: state => state.user.twitterName,
    mobile: state => state.user.mobile,
    points: state => state.user.points,
    payPasswordFlag: state => state.user.payPasswordFlag,
    currency: state => state.user.currency,
    official: state => state.user.official,
    isOtherPayMode: state => state.user.isOtherPayMode,
    paymodes: state => state.user.paymodes,
    avatar: state => state.user.avatar,
    userVip: state => state.user.userVip,
    loginType: state => state.user.loginType,
     // gamerole
     roleId: state => state.gamerole.roleId,
     roleName: state => state.gamerole.name,
     roleVip: state => state.gamerole.vip,
     roleLevel: state => state.gamerole.level,
     roleServerId: state => state.gamerole.serverId,
     roleServerName: state => state.gamerole.serverName,
 
     // pay
     discountList: state => state.pay.discountList,
     mode: state => state.pay.mode,
     amount: state => state.pay.amount,
     payCurrency: state => state.pay.currency,
     showBack: state => state.pay.showBack,
     productId: state => state.pay.productId,
     outOrderNo: state => state.pay.outOrderNo,

}
export default getters