<template>
    <van-row justify="center" v-if="installShow">
        <van-col  :span="24" >
            <div id="downGameBox">
                <div class="tipAddScreen">
                    <div class="tipBox">
                        <div class="tip" style="display: flex;">
                            <div class="x7Icon">
                                <img src="https://play.handzonegame.com/img/common/xz.png" alt="">
                            </div>
                            <div  class="text"> 下载到桌面 </div>
                        </div>
                    </div>
                </div>
            </div>
        </van-col>
    </van-row> 

    <van-row  justify="center">
        <van-col :span="24">
            <van-popup  v-model:show="showBottom"  round  position="bottom" style="height: 43%;" >
                <div class="top">
                    <img src="https://play.handzonegame.com/img/icons/apple-touch-icon-152x152.png" alt="">
                    <div class="top_box">
                        <div class="title"> 快速添加桌面应用 </div>
                        <div class="second_title"> 便捷启动·全屏体验 </div>
                    </div>
                    <div class="close">
                        <img @click="closeBottom" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABUCAMAAAArteDzAAAAjVBMVEUAAABmZmZmZmZra2tnZ2diYmJmZmZmZmZmZmZkZGRlZWVlZWVkZGRmZmZoaGhmZmZhYWFlZWVkZGRmZmZkZGRnZ2dnZ2dmZmZlZWVlZWVmZmZmZmZmZmZmZmZkZGRoaGhnZ2dlZWVmZmZlZWVmZmZmZmZmZmZmZmZlZWVlZWVlZWVmZmZnZ2doaGhqamo6s1tYAAAAK3RSTlMA+bkSVwbJfUEhFPSl4WAZC+ibaUXQDs5fVvC+7GQ3KxydkW+I3NrRsHRT+hcGAAAAAlNJREFUWMPtmNmOm0AURMHNbo/3AAYMeJm1l///vBDLUVC6pVIR52Ek12v7Xvc5lnAJ75lnvlnCdZ6Hj57sg8uxW09Zmn59lp1za3Kui2gRZPTK9W3yVAqboD9LbbSpA8GyJyd/GJRRa39dUGs1xI/Iu4b7xVwOg2ZzXP19ll8Ko6SU2p8tM4p9sbkNmipOraXHSCs5ROlZIAj27dzIXzHNJbVOu4VRt1PLAGS/TZnXJHRJ9bWUlgHIfud7j3vPThZEvpIjAwS78l/ilfMzIpjpkQGGXb/sUs+dbDkbGyDYf9i/PDbAs2MDPDs2wLNjAzw7NsCzYwM0O28As/MGMDtvALPzBjA7bwCz8wYwO28As/MGBGCfZkB4IWTnDWSInTcwL64fgJ03oORbowE7b0Bqhdh5A0MAOxGxnM3VaGnhZOfvWvxZqTZudt7r9U3Lu9dq2z6mtScfzW9+7dfL/CE7t3M9cgr6ANnr5Og5AEJ0m9Fz4J/Zzf2KlS9BH2DZldpsa6WAAZa9iNvhOYAM8J02x42I7bTO/wIc3G0EMDCtzwtkgO/z2ADf57EBnh0b4NmxAZ4dG+DZCQM0OzbAs7sNlGIKOzJwyHh2bOAgWHbeAGYnDfDsvAG+z4tybMB51+REd1pxGBvIbfb07POdNjuMDLShBf9VA3ZgwDSON2ifhVYWO2HAVHFnLS0joyx2woCpdr3ltDtJM+TdwY4NDIOq2K/tX6qMNlXzemfn7ho1VVVcXZOiPcaXpPf45MPkbr8KnYerNJ34TnrV9WvvmWf+V34Cl8HBtcvy2fIAAAAASUVORK5CYII=" alt="">
                    </div>
                </div>

                 <div class="bottom">
                    <van-swipe :autoplay="3000"  @change="onChange">
                        <van-swipe-item>
                            <img src="http://down1.msshuo.cn/x7sy/app/opera/i18n_language/content_file/1728358253829_1724320575853_tutorials_GIF1.gif" alt="" class="my-swipe_gif">
                        </van-swipe-item>
                        <van-swipe-item>
                            <img src="http://down1.msshuo.cn/x7sy/app/opera/i18n_language/content_file/1728358373468_1724323437526_tutorials_GIF2.gif" alt="" class="my-swipe_gif">
                        </van-swipe-item>
                        <van-swipe-item>
                            <img src="https://down1.msshuo.cn/x7sy/app/opera/i18n_language/content_file/1728358440834_1724323536190_tutorials_GIF3.gif" alt="" class="my-swipe_gif">
                        </van-swipe-item>
                    </van-swipe>

                    <div class="current">{{ stepNumber }}</div>
                 </div>
            </van-popup>
        </van-col>
    </van-row>
</template>
<script>
import { reactive, onMounted, toRefs } from "vue";
import { sendEvent } from "@/service/tiktokEvent";
export default {
    setup(){
       
        const state = reactive({
                installShow: true,
                showBottom: false,
                stepNumber: '第一步'
        });
        //点击安装按钮，弹出安装提示页面
        const  downGameBox = ()=>{
            document.getElementById('downGameBox').addEventListener('click',()=>{
                //判断当前系统
                var xt = gainSys();
                if(xt == 'h5'){
                    var prom = window.prom;
                    if(prom){
                        // 触发PWA安装
                        prom.prompt();
                        //监听安装结果
                        prom.userChoice.then((choiceResult)=>{
                            if(choiceResult.outcome === 'accepted'){
                                console.log('用户安装了PWA')
                                document.getElementById('downGameBox').style.display = "none";
                            }else{
                                console.log('用户拒绝安装PWA')
                            }
                        })
                    }
                }

                if(xt == 'ios'){ //引导用户书签的方式添加引用
                    state.showBottom = true; //显示提示按钮
                }
                if(xt == 'android'){ //需要判断是否符号安装

                }
    

                var panm = {
                    eventName: "ClickDownload",
                    eventTime: Math.round(new Date().getTime() / 1000).toString(),
                    properties: {
                        description: "用户点击了下载"
                    }
                }
                //发送点击事件
                sendEvent(panm);

            })
        }
       
        //获取系统类型
        const gainSys = ()=>{
            var u = navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
            var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isAndroid) {
                //这个是安卓操作系统
                return 'android';
            }
            if (isIOS) {
                //这个是ios操作系统
                return 'ios';
            }
            return 'h5';
        }
        //监听事件
        const onChange = (index)=>{
            if(index==0){
                state.stepNumber = '第一步';
            }
            if(index==1){
                state.stepNumber = '第二步';
            }
            if(index==2){
                state.stepNumber = '第三步';
            }
        }
        //关闭
        const closeBottom = ()=>{
            state.showBottom = false;
        }

        //初始化
        onMounted(() => {
            // 添加滚动事件监听器
            downGameBox();
        });

        return {
            ...toRefs(state),
            onChange,
            closeBottom
        };
    }
}
</script>
<style>
.tipAddScreen{
        font-size: 0.37333rem;
        color: #fff;
    }
    .tipAddScreen .tipBox {
        height: 1.33333rem;
    }

    .tipAddScreen .tip {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: fixed;
        height: 1.06667rem;
        bottom: calc(1.52rem + var(--safe-bottom));
        left: 50%;
        transform: translateX(-50%);
        padding: 0 0.42667rem;
        background: linear-gradient(90deg, #12cdb0, #12cd80);
        box-shadow: 0 0 0.26667rem 0 rgba(16,199,171,.58);
        border-radius: 0.53333rem;
        font-size: .37333rem;
        color: #fff;
        z-index: 2;
        bottom: 50px;
    }
    .tipAddScreen .tip .x7Icon, .tipAddScreen .tip .x7Icon img {
        width: 0.48rem;
        height: 0.55rem;
        
    }
    .tipAddScreen .tip .text{
        white-space: nowrap;
    }
    .top {
        margin: 0.64rem;
        margin-bottom: 0.53333rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .top img {
        width: 1.06667rem;
        height: 1.06667rem;
        margin-right: 0.26667rem;
    }
    .top .top_box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .top .title {
        font-size: 0.4rem;
        color: #333;
        font-weight: 700;
        line-height: 0.58667rem;
    }
  .top .second_title {
    font-size: 0.32rem;
    color: #999;
    line-height: 0.45333rem;
    text-align: left;
}
.top .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.12rem;
    height: 1.12rem;
}
 .top .close img {
    display: inline-block;
    margin: 0.37333rem;
    width: 0.37333rem;
    height: 0.37333rem;
}
 .bottom {
    position: relative;
    padding-bottom: 1.36rem;
    display: block;
    margin: 0 0.64rem;
    width: 8.72rem;
    height: 4.90667rem;
    border-radius: 0.32rem;
    -webkit-transition: all .5s;
    transition: all .5s;
    opacity: 1;
}
 .current {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 0.18667rem;
    height: 0.53333rem;
    background: -webkit-gradient(linear, right top, left top, from(#12cdb0), to(#12cd80));
    background: linear-gradient(270deg, #12cdb0, #12cd80);
    border-radius: 0.26667rem 0 0.26667rem 0;
    font-size: .29333rem;
    color: #fff;
    font-weight: 700;
    text-align: center;
    line-height: .53333rem;
}
 .my-swipe_gif {
    width: 8.72rem;
    height: 100%;
    border-radius: 0.32rem;
}
</style>