<template>
  <div class="video-player"  ref="scrollContainer" id="video-dom" >
    <van-swipe ref="mySwipe" style="width: 100%;  height: 100%;  " @change="changeSwipe" :stop-propagation="false"   vertical  :show-indicators="false" @touchstart="handleTouchStart" @touchend="handleTouchEnd" >
      <van-swipe-item class="swipe-ite" style="width: 100%; ">
          <video id="myVideo" class="video-js" playsinline  controls data-setup="{}">

          </video>
      </van-swipe-item>
      <van-swipe-item class="swipe-ite" style="width: 100%; ">       

      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>

import {   onUnmounted ,inject,defineExpose,watch,onMounted,ref} from 'vue'; //onMounted  watch
import { Toast  } from "vant";
//import { useStore } from 'vuex'
import videojs from 'video.js';
export default {
  props: {
    episodes: { //资源数组
      type: Array,
      required: true
    },
    selectedEpisode: { //当前集数
      type: Number,
      default: 0
    },
    playletId:{ //短剧ID
      type: Number,
      default: 0
    }
  },
  setup(props,context) {
    let player = null;
     // 注入数据
    const store = inject('store');
    //对应轮播滑动的下标
    const swipeIndex = ref(0);

    //记录滑动时 开始的y坐标，结束后y坐标
    const client = ref({
       startY: 0, //开始时y坐标
       endY: 0, //结束时y坐标
       startX: 0, //开始时x坐标
       endX: 0 //结束时x坐标
    })
    const mySwipe = ref(0);
    
    const changeEpisode = () => {
      reinitializePlayer();


      player.on('play', () => {
        context.emit('pause-button',false);//隐藏掉暂停按钮
        console.log('Video is playing');
      });

      player.on('pause', () => {
        context.emit('pause-button',true);//显示暂停按钮
        console.log('Video is paused');
      });

      //播放结束通知父组件，请求播放下一集
      player.on('ended',()=>{
          playback();
      });
      player.on('error', function(player, err){
            alert(`播放器 ${player.id()} 出错，代码为 ${err.code} ${err.message}`);
      });
      // 监听loadedmetadata事件
      player.on('loadedmetadata', function() {
        // 获取视频总时长
        var duration = player.duration();
        console.log('总时长'+duration)
      });
      player.on('timeupdate', () => {
          const currentTime = player.currentTime();
          updatePlayRecord({ episodeId: props.selectedEpisode, time: currentTime });
      });

      // 添加自定义事件监听器
      /* player.el().addEventListener('touchstart', function(event) {
            event.preventDefault();
        }, { passive: true });*/
      //  console.log(player)

      //根据集数获取用户该视频的播放时间
      const savedTime = getPlayRecord(props.selectedEpisode);
      if (savedTime > 0) {
        player.currentTime(savedTime);
      }
    };

    //获取播放该集的时间
    const getPlayRecord = (episodeId) => {
      var time = 0;
      var records =  store.state.app.records;
      var totalTime = 0;
      for(var i = 0;i< records.length; i++){
        var recod =  records[i];
        if(props.playletId == recod.playletId &&   recod.episId == episodeId){
          time = recod.te;
          totalTime = recod.totalTime;
          break;
        }
      }
      console.log("短剧ID:"+props.playletId + "  集数：" + props.selectedEpisode +   " 时长："+time + "总时长："+totalTime )
      //首先缓存一次避免缓存多次
      if(time == 0){
        const currentTime = player.currentTime();
        updatePlayRecord({ episodeId: props.selectedEpisode, time: currentTime })
      }
      if(totalTime != 0 && time == totalTime){ //代表已经完整播放此视频，设置为0，从新播放
        updatePlayRecord({ episodeId: props.selectedEpisode, time: 0 })
        time = 0;
      }
      return time;
    };
    //缓存当前播放集数，以及播放的时间
    const updatePlayRecord = ({ episodeId, time }) => {
      store.commit("setRecords",{ 
          playletId: Number(props.playletId),
          episId: episodeId,
          te: time,
          totalTime: player.duration()
       })
    };
    //播放完成后自动播放下一集,返回状态给父主键
    const playback = ()=>{
        context.emit('play-back',1)
    }
    //暴露方法，暂停视频播放
    const suspend = () => {
        player.ready(function() {
         // console.log('已经暂停视频播放！！！！！！！！！')
          player.pause();
        });
    }
    //暴露方法，视频播放
    const playVideo = () =>{
      console.log("播放方法打印：{}",player)
      player.play();
    }
    //根据下标返回变量数组返回
    const traverse = () =>{
       for(var i = 0;i < props.episodes.length; i++){
            var episo = props.episodes[i];
            if(episo.sort == props.selectedEpisode){
                return episo.fileUrl;
            }
       }
    }
    //创建video视频dom
    const initializePlayer = ()=>{
      var url = traverse();
      player = videojs('myVideo', {
        controls: true,
        autoplay: true, //自动播放
        preload: 'auto',
        sources: [{
          src: url,
          type: 'video/mp4'
        }]
      }, function() {
          console.log('Player is ready!');
      });

      //删除自带暂停按钮
      var docu =  document.getElementsByClassName('vjs-big-play-button');
      if(docu){
          docu[0].remove();
      }
    }

    //初始化播放器
    const reinitializePlayer = ()=>{
      if (player) {
          player.pause();   //关闭
          player.dispose(); //销毁
          player = null;    // 清除引用
      }
      var videoElement = document.getElementById('myVideo');
      if (!videoElement) {
          var elements =  document.getElementsByClassName('swipe-ite');
          //重新创建dom
          var element = elements[swipeIndex.value];
          element.innerHTML = '<video id="myVideo" class="video-js" playsinline style="overflow-y: hidden; overflow-x: hidden; pointer-events: auto;width:100%;height:100%;" controls> </video>';
      }
      // 确保 DOM 元素存在后再初始化
      if (document.getElementById('myVideo')) {
          initializePlayer();
      } else {
        console.info('Video element with ID "myVideo" does not exist.');
      }
    }
    //获取下拉时指定生成视频放入那个div中
    const changeSwipe = (index)=>{
      swipeIndex.value = index;
      //通知父组件初始化播放下一集内容
     // context.emit('play-back',{})
    }

    //记录出面时的y坐标
    const handleTouchStart = (event)=>{
        client.value.startY =  event.touches[0].clientY;
        client.value.startX = event.touches[0].clientX;
    }

    //记录结束时的y坐标
    const handleTouchEnd = (event)=>{
      client.value.endY =  event.changedTouches[0].clientY;
      client.value.endX =  event.changedTouches[0].clientX;
      // 计算滑动距离
      const distance =  client.value.endY -  client.value.startY ;
      const deltaX =    client.value.endX -  client.value.startX;
      // 判断滑动方向
      if(Math.abs(deltaX) > Math.abs(distance)){
         //暂不出来左右滑动事件
      }else{ //上下滑动
        if(distance > 60){
          //判断上滑集数如果-1小于等于0，不让往上滑动

          if(props.selectedEpisode - 1 <= 0){
            //用于停留在第一页
            mySwipe.value.swipeTo(0)
            Toast("已经是第一集了！！！")
            return;
          }
          context.emit('play-back',-1)
        }else if(distance < -100){
          if(props.selectedEpisode + 1 > props.episodes.length){
            //用于停留在最后一页
            mySwipe.value.swipeTo(props.episodes.length-1)
            Toast("已经是最后一集了！！！")
            return;
          }
          context.emit('play-back',1)
        }
      }
    }

    //初始化播放器
    onMounted(() => {
       // 添加滚动事件监听器
    });

    onUnmounted(() => {
      if (player) {
          player.dispose();
      }
    });

   /* props.selectedEpisode, () => {
        //监听selectedEpisode 值发生变化
        changeEpisode();
        //自动播放
       // playVideo();
      }*/

    watch(props, () => {
        console.log("发生变化！！")
        //监听selectedEpisode 值发生变化
        changeEpisode();

        playVideo();
      }
    );

    defineExpose({
       suspend,
       playVideo,
       changeEpisode
    });

    return {
      changeEpisode,
      playback,
      suspend,
      playVideo,
      changeSwipe,
      handleTouchStart,
      handleTouchEnd,
      mySwipe
    };
  }
};
</script>

<style scoped>
.video-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(var(--vh, 1vh) * 95);
}
.video-js {

    overflow-y: hidden; /* 启用垂直滚动条 */
    overflow-x: hidden; /* 启用垂直滚动条 */

    min-height: 100%;
    min-width: 100%;
   /* object-fit: cover;
    bject-position: center center;*/
    width:100%;
    height:100%;
}

</style>