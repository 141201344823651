<template>
      <div class="game-content"  style="width:100%;height: calc(var(--vh, 1vh) * 95);overflow-y: scroll;">
        <div class="tab-content" style="width:100%;">
            <!-- 头部    -->
            <van-row justify="center" >
                <van-col  :span="24" >
                    <HeaderGame/>
                </van-col>
            </van-row>
            <van-row justify="center" style="margin-top: 14px;">
                <van-col  :span="24">
                    <div  id="iframeGame" v-if="detailShow" >
                          <iframe class="frame"  id="gameframe" ref="gameframe" :src="detailUrl" frameborder="0" @load="onLoad"  />
                    </div>
                    <div class="MuiBox-root css-1r69lwr" v-else>
                        <div class="InstantThumb_instantThumb__yimdR" v-for="(item, index) in data" :key="index"  @click="skip(item)">
                            <a href="#" >
                                <div class="InstantThumb_frontArea__Nixk6">
                                    <img  :src="item.imgUrl"   :alt="item.title">
                                    <div class="InstantThumb_gameNameContainer__9R5tW">{{item.title}}</div>
                                </div>
                            </a>
                            <div class="InstantThumb_thumbOverlay__d9rna">
                                <div class="InstantThumb_thumbBlur__26KA0">
                                    <img class="InstantThumb_gameBack__kYLZ7" :srcset="item.srcset"  :alt="item.title">
                                </div>
                            </div>
                        </div>
                    </div>
                </van-col>
            </van-row>
        </div>
    </div>
    <DownLoadApply/>   
    <!-- 底部    -->
    <van-row justify="center" >
        <van-col  :span="24" >
            <nav-bar />
        </van-col>
    </van-row>
</template>
<style>
 .frame {
     width: 100%;
     height: calc(var(--vh, 1vh) * 100);
 }
 .css-1r69lwr {
    width: 100%;
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    position: relative;
}
.InstantThumb_instantThumb__yimdR {
    width: 100%;
    border-radius: 16px;
    position: relative;
    aspect-ratio: 186 / 237;
}
.InstantThumb_instantThumb__yimdR .InstantThumb_frontArea__Nixk6 {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.InstantThumb_instantThumb__yimdR .InstantThumb_thumbOverlay__d9rna {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 16px;
    z-index: 1;
}
.InstantThumb_instantThumb__yimdR .InstantThumb_thumbOverlay__d9rna .InstantThumb_thumbBlur__26KA0 {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--black-100);
}
.InstantThumb_instantThumb__yimdR .InstantThumb_thumbOverlay__d9rna .InstantThumb_thumbBlur__26KA0 img.InstantThumb_gameBack__kYLZ7 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    border-radius: 16px;
    opacity: .3;
}
.InstantThumb_instantThumb__yimdR .InstantThumb_frontArea__Nixk6>img {
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 1;
}
.InstantThumb_instantThumb__yimdR .InstantThumb_frontArea__Nixk6 div.InstantThumb_gameNameContainer__9R5tW {
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    padding: calc(var(--base-unit)* .5) calc(var(--base-unit)* 1);
    margin: auto;
}
</style>
<script>
    import NavBar from '@/components/NavBar'
    import HeaderGame from '@/components/HeaderGame'
    import { reactive,toRefs ,nextTick} from "vue";  //nextTick
    import DownLoadApply from '@/components/DownLoadApply'
    export default{
        name:'consoleGame',
        setup(){
            const state = reactive({
                detailUrl: 'https://playofbox.com/',
                detailShow: true,
                data:[
                    {
                        imgUrl:'https://img.cdn.famobi.com/portal/html5games/images/tmp/OmNomRunTeaser.jpg?v=0.2-766f7fc0',
                        link: 'https://html5games.com/Game/Om-Nom-Run/abe80572-560a-444d-baf7-2fa4a7b2c02f', // 'https://www.crazygames.com/game/mahjongg-solitaire',
                        title:'Om Nom Run',
                        epitome:'https://img.cdn.famobi.com/portal/html5games/images/tmp/OmNomRunTeaser.jpg?v=0.2-766f7fc0',
                        srcset:'	https://img.cdn.famobi.com/portal/html5games/images/tmp/OmNomRunTeaser.jpg?v=0.2-766f7fc0'
                    },
                    {
                        imgUrl:'https://img.cdn.famobi.com/portal/html5games/images/tmp/ElementBlocksTeaser.jpg?v=0.2-766f7fc0',
                        link: 'https://html5games.com/Game/Element-Blocks/8c70b6c7-5792-4c40-b891-496eef2fb5ed', //  'https://www.crazygames.com/game/block-champ',
                        title:'Element Blocks',
                        epitome: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/ElementBlocksTeaser.jpg?v=0.2-766f7fc0',
                        srcset:"https://img.cdn.famobi.com/portal/html5games/images/tmp/ElementBlocksTeaser.jpg?v=0.2-766f7fc0"
                    },
                    {
                        imgUrl: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/ZooBoomTeaser.jpg?v=0.2-766f7fc0',
                        link: 'https://html5games.com/Game/Zoo-Boom/5e772ebe-9e0a-4cd3-adcf-bba662f35535',
                        title: 'Zoo Boom',
                        epitome: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/ZooBoomTeaser.jpg?v=0.2-766f7fc0',
                        srcset: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/ZooBoomTeaser.jpg?v=0.2-766f7fc0'
                    },
                    {
                        imgUrl: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/BubbleWoodsTeaser.jpg?v=0.2-766f7fc0',
                        link: 'https://html5games.com/Game/Bubble-Woods/53159388-d515-45e5-b65a-56d21c595405',
                        title: 'Bubble Woods',
                        epitome: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/BubbleWoodsTeaser.jpg?v=0.2-766f7fc0',
                        srcset: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/BubbleWoodsTeaser.jpg?v=0.2-766f7fc0'
                    },
                    {
                        link: 'https://html5games.com/Game/Diamond-Rush-2/d2e4616c-9cb2-4e7f-aa73-abe0c7e295fe',
                        imgUrl: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/DiamondRush2Teaser.jpg?v=0.2-766f7fc0',
                        title: 'Diamond Rush 2',
                        epitome: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/DiamondRush2Teaser.jpg?v=0.2-766f7fc0',
                        srcset: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/DiamondRush2Teaser.jpg?v=0.2-766f7fc0'
                    },
                    {
                        link: 'https://html5games.com/Game/Pop-Pop-Rush/acc35022-6b92-4580-91e9-65fbd634c4dc' ,
                        imgUrl: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/PopPopRushTeaser.jpg?v=0.2-766f7fc0',
                        title: 'Pop Pop Rush',
                        epitome: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/PopPopRushTeaser.jpg?v=0.2-766f7fc0',
                        srcset: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/PopPopRushTeaser.jpg?v=0.2-766f7fc0'
                    },
                    {
                        link: 'https://html5games.com/Game/Animal-Quiz/a5db53c9-e8fe-47c5-aba3-8148d460a4f3',
                        imgUrl: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/AnimalQuizChristmasTeaser.jpg?v=0.2-766f7fc0',
                        title: 'Animal Quiz',
                        epitome: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/AnimalQuizChristmasTeaser.jpg?v=0.2-766f7fc0',
                        srcset: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/AnimalQuizChristmasTeaser.jpg?v=0.2-766f7fc0'
                    },
                    {
                        link: 'https://html5games.com/Game/Fluffy-Egg/e56a7d82-1c07-4241-bab1-7ef17c8576ba',
                        imgUrl: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/FluffyEggTeaser.jpg?v=0.2-766f7fc0',
                        title: 'Fluffy Egg',
                        epitome: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/FluffyEggTeaser.jpg?v=0.2-766f7fc0',
                        srcset: 'https://img.cdn.famobi.com/portal/html5games/images/tmp/FluffyEggTeaser.jpg?v=0.2-766f7fc0'
                    }
                ]
            });

            const skip = (item)=>{
                state.detailShow = true;
                state.detailUrl = item.link;

                //跳转到详情页
              /*  nextTick(()=>{
                    //加载谷歌登录资源
                    const iframe = document.createElement('iframe');
                    iframe.src = item.link;
                    iframe.class = "gameframe";
                    iframe.refs = "gameframe";
                    console.log( document.getElementById("iframeGame") )
                   document.getElementById("iframeGame").append(iframe);
         
                });*/
               
              
             //   document.getElementById("frame").append(iframe);
         
            }
            
            nextTick(()=>{
                //初始化谷歌登录参数
       
                //
                window.$('#gameframe').on("load",function(){
                  //  var frame =  document.getElementById('gameframe');
                   // 通过 querySelector 或其他方式获取 iframe 内部的元素
    
                  //  var iframeDoc = frame.contentDocument || frame.contentWindow.document;
                    //监听消息
                   // console.log(iframeDoc)
                })
            }) 
            return {
                ...toRefs(state),
                skip
            };
        },
        components: {
             NavBar,
             HeaderGame,
             DownLoadApply
        }
    }
</script>