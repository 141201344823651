<template>
    <div id="game-content"  style="width:100%;height: calc(var(--vh, 1vh) * 95);overflow-y: auto;">
        <div class="tab-content" style="width:100%;">

            <van-row justify="space-between">
                <van-col :span="24">
                    <HeaderGame  @skip-page="skipPageEvent"></HeaderGame>
                </van-col>
            </van-row>

            <div class="container">
            
                    
                <div class="feature-card">
                    <!-- 标题 -->
                    <van-row  justify="center" style="margin-bottom:19px;" >
                        <van-col :span="24">
                            <div class="hero">
                                <h2>
                                    {{title}}
                                </h2>
                            </div>

                        </van-col>
                    </van-row>

                    <!--  游戏iframe -->
                    <van-row  justify="center" >
                        <van-col :span="24">
                            <div>
                                <iframe   :src="detailUrl" style="background:black;" width="100%" height="600"  scrolling="no" frameborder="0" />
                            </div>
                        </van-col>
                    </van-row>

                    <!-- 游戏视频攻略 -->
                    <van-row justify="center" style="margin-bottom:20px;">
                        <van-col :span="24">
                            <div class="page-cover" data-text="Game Walkthrough">
                                Game Walkthrough
                            </div>
                            <!--  用于展示视频 -->
                            <div id="gamemonetize-video" style="margin-top:10px;">
                            
                            </div>
                        </van-col>
                    </van-row>
                </div>
            </div>

           

            <van-row justify="center" >
                <van-col  :span="24" >
                    <DownLoadApply/> 
                </van-col>
            </van-row>

            <!-- 底部    -->
            <van-row justify="center" >
                <van-col  :span="24" >
                    <nav-bar />
                </van-col>
            </van-row>

        </div>
    </div>
</template>
<script>
     import NavBar from '@/components/NavBar';
     import DownLoadApply from '@/components/DownLoadApply';
     import HeaderGame from '@/components/HeaderGame';
     import { reactive,toRefs,onMounted,nextTick,onUnmounted } from "vue";  //nextTick
     import { useStore } from 'vuex';
     import { useRouter } from "vue-router";
     export default {
        setup(){
            const store = useStore();
            const router = useRouter();
            const state = reactive({
                title: '',
                detailUrl: '',
                id: ''
            })


            //获取点击缓存的数据
            const getCacheData = ()=>{
                let miniGame = store.state.app.miniGame;
                state.title = miniGame.title;
                state.detailUrl = miniGame.url;
                state.id = miniGame.id;
            }

            //子组件跳转主页
            const skipPageEvent = ()=>{
                console.log('跳转')
                //跳转到详情页
                router.push({ path: '/mini/OnlineMinigames', query: {}})
            }

            onMounted(()=>{
                getCacheData();
                console.log('创建了详情页面')
                nextTick(()=>{
                    //从游戏链接中提取id
                    var game =   state.detailUrl.split('/');
                    window.VIDEO_OPTIONS = {
                        gameid : game[3],
                        width  : "100%",
                        height : "480px",
                        color  : "#3f007e"
                    };
                    (function (a, b, c) {
                        var d = a.getElementsByTagName(b)[0];
                        a.getElementById(c) || (a = a.createElement(b), a.id = c, a.src = "https://api.gamemonetize.com/video.js?v=" + Date.now(), d.parentNode.insertBefore(a, d))
                    })(document, "script", "gamemonetize-video-api"); 
                })
            })

            onUnmounted(()=>{
             //获取id为gamemonetize-video-api js文件
                var video =  document.getElementById("gamemonetize-video-api");
                if(video){
                    //清除掉之前的
                    video.remove();
                }
            })

            return {
                ...toRefs(state),
                skipPageEvent
            }
        },
        components:{
            NavBar,
            DownLoadApply,
            HeaderGame
        }
     }
</script>
<style>
    #game-content{
        background-image:radial-gradient(circle 50vw at 100% 0,#00fefe,transparent),radial-gradient(circle 50vw at 20% 25vw,#9b00cc,transparent),linear-gradient(180deg,#3f007e,#502cba 25vw,#05122b 50vw);
        padding-top: 15px;
        color: #fff;
        font-size: 0.3rem;
    }
    .container{
        padding-left: 20px;
        padding-right: 20px;
        
    }
    .feature-card{
        padding: 20px;
        position: relative;
        text-align: center;
        border-radius: 5px;
        box-shadow: 0 0 0 1px rgba(255,255,255,.3);
        height: 100%;

    }
   .feature-card:before {
       /* content: "";*/
        position: absolute;
        top: -5px;
        right: 5px;
        bottom: -5px;
        left: 5px;

        border-radius: inherit;
        box-shadow: 0 0 0 1px rgba(255,255,255,.1);
    }
    .hero h2{
        font-size: 38px !important;
        font-weight: 700;
        box-sizing: border-box;
    }

    .page-cover{
        text-align: left;
        font-weight: 700;
        font-size: 25px !important;
        opacity: 0.8;
        border-bottom: 1px solid rgba(255,255,255,.7);
        margin-top: 30px;
        -webkit-text-stroke: 1px #fff;
        display: block;
    }
    .page-cover:after{
      /*  content: attr(data-text);*/
        position: absolute;
        left: -1px;
        top: 1px;
        color: #ff2c9c;
    }
</style>