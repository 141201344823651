<template>
      <div id="game-content"  style="width:100%;height: calc(var(--vh, 1vh) * 95);overflow-y: auto;">
        <div class="tab-content" style="width:100%;"></div>
            <van-row justify="space-between">
                <van-col :span="24">
                    <HeaderGame/>
                </van-col>
            </van-row>
            <van-row justify="center"  style="width:100%;">
                <van-col  :span="24" >
                    <div v-if="detailShow" >
                        <iframe class="gameframe" ref="gameframe" :src="detailUrl" frameborder="0"  @load="onLoad"/>
                    </div> 

                    <div class="container" v-else>
                        <div class="row">
                            <div class="col-lg-4"  v-for="(item, index) in data" :key="index"  @click="skip(item)">
                                <div>
                                    <img alt="" :src="item.thumb" class="game-card__cover" />
                                    <h3 class="game-card__title">{{item.title}}</h3>
                                </div>
                            </div>

                        </div>
                    </div>
                </van-col>
            </van-row>
        

            <van-row justify="center" v-if="detailShow" >
                <van-col  :span="24">
                    <!-- 关闭按钮 -->
                    <div class="layerCloseButton_container__ENx95" >
                        <div class="layerCloseButton_btnBg__p1vOU" @click="closePlay">
                            <span class="material-symbols-outlined">
                                close
                            </span>
                        </div>
                    </div>
                </van-col>
            </van-row>
            <van-row justify="center" >
                <van-col  :span="24" >
                    <DownLoadApply/> 
                </van-col>
            </van-row>

            <!-- 底部    -->
            <van-row justify="center" >
                <van-col  :span="24" >
                    <nav-bar />
                </van-col>
            </van-row>
    </div>
 </template>
 <script>
     import NavBar from '@/components/NavBar';
     import { reactive,toRefs,onMounted,nextTick } from "vue";  //nextTick
    // import axios from 'axios';
     import { useStore } from 'vuex';
     import DownLoadApply from '@/components/DownLoadApply';
     import { useRouter } from "vue-router";
     import HeaderGame from '@/components/HeaderGame';
     import { getGameMone } from "@/service/web";
     import { sendEvent } from "@/service/tiktokEvent";
     export default {
         name: "onlineMiniGames",
         setup(){
            const store = useStore();
            const router = useRouter();
            const state = reactive({
                data:[],
                detailShow: false,
                detailUrl: '',
                page: 1,
                loading: false,
                finished: false,
                show: true
            })

            //根据url获取游戏列表
            const gamesList = async () => {
                var result = await getGameMone({
                    num: 90,
                    page: state.page
                })
                result.data.forEach(item => {
                    state.data.push(item);
                });
            };
            //点击了游戏
            const skip = (rem) => {
           
                // state.detailShow = true;
                //  state.detailUrl = rem.url;
                //缓存当前游戏信息
                store.commit("setMiniGame",rem)
                var panm = {
                    eventName: "ClickButton",
                    eventTime: Math.round(new Date().getTime() / 1000).toString(),
                    properties: {
                        description: rem.id
                    }
                }
                //发送点击事件
                sendEvent(panm);
                //跳转到详情页
                router.push({ path: '/mini/GameIntroduction', query: {}})
            }
            //点击关闭按钮关闭游戏
            const closePlay = () => {
                state.detailShow = false;
            }
            const onLoad = () =>{}
            onMounted(()=>{ //获取游戏列表
                gamesList();
  
                nextTick(()=>{
                    var cont = document.getElementById('game-content')
                    cont.addEventListener('scroll', ()=>{
                        // 获取当前滚动高度
                        var scrollTop =    cont.scrollTop;
                        // 获取元素的总高度
                        var scrollHeight =    cont.scrollHeight;
                        // 设置偏移量
                        if(cont.clientHeight+scrollTop == scrollHeight){
                            //重新刷新获取下一页
                            state.page =  state.page + 1;
                            //延时10秒后在执行，打开弹框显示加载中
                            gamesList();
                        }
                    });
                })
            })

            return {
                ...toRefs(state),
                skip,
                closePlay,
                onLoad
            };
         },
         components: {
             NavBar,
             DownLoadApply,
             HeaderGame
         }
     }
 </script>
 <style>

 .gameframe {
     width: 100%;
     height: 600px;
 }
 .css-1r69lwr {
    width: 100%;
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    position: relative;
}
.InstantThumb_instantThumb__yimdR {
    width: 100%;
    border-radius: 16px;
    position: relative;
    aspect-ratio: 186 / 237;
}
.InstantThumb_instantThumb__yimdR .InstantThumb_frontArea__Nixk6 {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.InstantThumb_instantThumb__yimdR .InstantThumb_thumbOverlay__d9rna {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 16px;
    z-index: 1;
}
.InstantThumb_instantThumb__yimdR .InstantThumb_thumbOverlay__d9rna .InstantThumb_thumbBlur__26KA0 {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--black-100);
}
.InstantThumb_instantThumb__yimdR .InstantThumb_thumbOverlay__d9rna .InstantThumb_thumbBlur__26KA0 img.InstantThumb_gameBack__kYLZ7 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    border-radius: 16px;
    opacity: .3;
}
.InstantThumb_instantThumb__yimdR .InstantThumb_frontArea__Nixk6>img {
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 1;
}
.InstantThumb_instantThumb__yimdR .InstantThumb_frontArea__Nixk6 div.InstantThumb_gameNameContainer__9R5tW {
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    padding: calc(var(--base-unit)* .5) calc(var(--base-unit)* 1);
    margin: auto;
}
.layerCloseButton_container__ENx95 {
        -webkit-app-region: no-drag;
        align-items: center;
        display: flex;
        left: 10px;
        position: absolute;
        top: 40px;
        z-index: 20;
    }
    .layerCloseButton_btnBg__p1vOU {
        align-items: center;
        background: #01050d80;
        border: 1px solid #ffffff26;
        border-radius: 32px;
        cursor: pointer;
        display: flex;
        height: 40px;
        justify-content: center;
        width: 40px;
    }
    .material-symbols-outlined {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        color: rgba(228, 232, 238, 0.836);
        -webkit-font-smoothing: antialiased;
    }

    #game-content{
        background-image:radial-gradient(circle 50vw at 100% 0,#00fefe,transparent),radial-gradient(circle 50vw at 20% 25vw,#9b00cc,transparent),linear-gradient(180deg,#3f007e,#502cba 25vw,#05122b 50vw);
        padding-top: 15px;
        color: #fff;
        font-size: 0.3rem;
    }
    .container{
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }
    .row{
        border-radius: 4px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }
    .game-card__title{
        line-height: 0.6rem;
        font-size: 15px;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 7px;
    }
    .col-lg-4{
        flex: 1 0 16.33%;
        max-width: 33.33%;
        padding: 6px;
    }
    .game-card__cover{
        width: 100%;
        object-fit: cover;
        border-radius: 6px;
    }
 </style>