import axios from '../utils/axios'


export function getPlaylet(){
    return axios.get(`/playlet/list`)
}


export function episodesList(id){
    return axios.get(`/playlet/episodes/${id}`)
}


export function bingeWatch(params){
    return axios.post('/playlet/bingeWatch', params)
}

export function cancelWatch(params){
    return axios.post('/playlet/cancelWatch', params)
}

export function bingeWatchList(){
    return axios.get('/playlet/bingeWatchList')
}