<template>
    <div class="episode-categories">
       <van-popup v-model:show="show"  position="bottom" :style="{ height: '50%' }" round closeable @click-overlay="closeEvent"  @click-close-icon="closeEvent">        
          <!-- 自定义标题 -->
          <div style="display: flex;padding: 21px;">
              <div style="max-width:80px;">
                   <img :src="plaIcon" style="width: 100%;border-radius: 10px;">
              </div>
              <div style="height: 30px;width: 50%;text-align: left;padding: 10px;">
                <div style="font-size: 22px;margin-left: 10px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-top: 12px;">
                    <span >{{plaName}}</span>
                </div>
 
               <p style="font-size: 16px;margin-left: 10px;margin-top: 5px;color: rgb(157,157,157);" >{{tag}}</p>
              </div>
              <div style="line-height: 80px">
                  <van-button class="join-drama did-not" @click="bingeWatch" v-if="!isDrama" >
                      <span style="color: aliceblue;">加入追剧</span>
                  </van-button>

                  <van-button class="join-drama joined" @click="cancelWatch" v-else >
                      <span style="color: rgb(149, 149, 149);font-size: 18px;">已追</span>
                  </van-button>
              </div>
              <!-- 加入追剧 -->
          </div>
          <!-- 内容 -->
          <div>
              <van-tabs v-model:active="active">
                  <van-cell-group v-for="(category, index) in categories" :key="index">
                    <van-tab :title="category.title" style="font-size: 0;">
                        <div v-for="(episode, i) in category.episodes" :key="i" class="episode"  @click="changeEpisode(episode,$event)" >
                            <div class="anthology">
                                {{ episode }}       
                            </div> 
                        </div>
                    </van-tab>
                  </van-cell-group>
              </van-tabs>
          </div>

          </van-popup>
    </div>
  </template>
  <script >
  import {  computed,reactive,toRefs,defineExpose ,nextTick} from 'vue';
  import {  CellGroup } from 'vant';
import { Dialog} from 'vant'
  export default {
    name:'EpisodeCategories',
    components: {
      VanCellGroup: CellGroup,
    },
    props: {
      totalEpisodes: { //总集数
        type: Number,
        required: true,
      },
      plaIcon:{
         type: String,
         default: ''
      },
      plaName:{
         type: String,
         default: ''
      },
      tag:{
        type:String,
        default:''
      },
      isDrama:{
        type: Boolean,
        default: false
      }
    },
    setup(props,context) {
        // 定义 emits 选项
        const state = reactive({
            show: false
        })
        // 计算分类
        const categories = computed(() => {
            const result = [];
            const episodesPerCategory = 24;

            for (let i = 1; i <= props.totalEpisodes; i += episodesPerCategory) {
            const start = i;
            const end = Math.min(i + episodesPerCategory - 1, props.totalEpisodes);
            const title = ` ${start} -  ${end}`;
    
            result.push({
                title,
                episodes: Array.from({ length: end - start + 1 }, (_, k) => start + k),
            });
            }
            return result;
      });
      //监听选择集数，返回所选项给父组件
      const changeEpisode = (episode,event)=>{
          context.emit('custom-event',episode)
          //关闭弹框
          state.show = false ;
          //获取所有的元素，复原class样式
          // 假设您要获取所有class为"my-class"的元素
          var elements = document.getElementsByClassName("anthology");
            // 遍历所有获取到的元素
          for (var i = 0; i < elements.length; i++) {
                var element = elements[i];
                // 对element进行操作
                element.className = 'anthology';
          }
          //增加目标元素改变字体颜色，表示当前播放集数
          event.target.className = 'anthology when-selected';
      }
      //监听关闭
      const closeEvent = ()=>{
        context.emit('close-xjdt',{})
      }
      //打开选集弹框弹框
      const openAntholigy = ()=>{
          state.show = true;
      }
      //提供给父组件改变所选集数字体颜色，用来标记播放到多少集
      const whenSelected = (sort)=>{
            nextTick(()=>{
                var elements = document.getElementsByClassName("anthology");
                console.log("对应下标："+sort  + "获取元素的小标"+elements.length)
                for (var i = 0; i < elements.length; i++) {
                    var element = elements[i];

                    console.log(   )
                    if(element.innerText == sort){
                        // 对element进行操作
                        element.className = 'anthology when-selected';
                        console.log("执行到了")
                    }else{
                        // 对element进行操作
                        element.className = 'anthology';
                    }    
                }
            });
      }
      //加入追剧
      const bingeWatch = ()=>{
        state.show = false;
        //通知给父组件
        context.emit('binge-watch',1)
      }
      //取消加入
      const cancelWatch = ()=>{
        state.show = false;
        //加入一个提示
            //加一个退出提示
            Dialog.confirm({
                title: '确认取消追剧吗？',
                message: '取消后可能找不到本剧哦~',
                confirmButtonText: '确认',
                cancelButtonText: '取消'
             })
             .then(() => {
                    context.emit('binge-watch',2)
             })
             .catch(() => {
                // on cancel
             });

      }


      //暴露子组件方法
      defineExpose({
          openAntholigy,
          whenSelected
      });
    
      return {
            ...toRefs(state),
            categories,
            changeEpisode,
            closeEvent,
            openAntholigy,
            whenSelected,
            bingeWatch,
            cancelWatch
      };
    },
  };



  </script>
  
  <style scoped>
  .episode-categories {
    margin: 16px;

  }
  .episode{
        font-size: 16px;
        text-align: center;
        display: inline-block;
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
        margin-right: 10px;
  }
  .anthology{
      width: 50px;
      height: 50px;
      background-color:  rgb(248,249,250);
      line-height: 50px;
      border-radius: 0.2rem;
  }
  .when-selected{
      color: rgb(225, 68, 46);
  }
  .join-drama{

     width: 100px;
     border-radius: 50px;
     height: 42px;
  }
  .did-not{
    background-color: rgb(225, 68, 46);
  }
  .joined{
    background-color: rgb(247, 248, 250);
  }
  </style>