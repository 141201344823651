<template>
    <div class="game-content"  style="width:100%;height: calc(var(--vh, 1vh) * 95);overflow-y: scroll;">
      <div class="tab-content" style="width:100%;">
          <!-- 头部    -->
          <van-row justify="center" >
              <van-col  :span="24" >
                  <HeaderGame/>
              </van-col>
          </van-row>
          <!--   广告单元    -->
          <!--   <van-row>
            <van-col  :span="24" >
                <div class="ad-container" v-html="adsenseCode">
                </div>
            </van-col>
          </van-row>  -->
          <van-row justify="center">
              <!-- 标题 -->
              <van-col  :span="24">
               <!-- <div  id="iframeGame">
                        <iframe class="gameframe" ref="gameframe" :src="detailUrl" frameborder="0" @load="onLoad"  />
                  </div> -->
                <div style="width: 100%;text-align: center;">
                    <div class="sKHSJ">{{title}}</div>
                </div> 
              </van-col>
          </van-row>

          <!-- 内容  -->
         <van-row  justify="center">
            <van-col  :span="24">
                <div>
                    <div class="_18p7x">
                        <div class="dpu8C _2kCxD" v-html="html">

                        </div>
                    </div>
                </div>
            </van-col>
          </van-row>

            <!--  新闻列表显示    -->
             <!--  <van-row justify="center"  >
                <van-col  :span="24" >
                    <div style="text-align: left;padding: 15px 15px 0px 15px;display: flex;" @click="cut">
                        <span class="material-symbols-outlined">
                            autorenew
                        </span>
                        <span style="font-size: 15px;margin-top: 2px;">换一换</span>
                    </div>
                </van-col>
            </van-row> -->
            <van-row justify="center" >
                <van-col :span="24">
                    <div class="news" v-for="(item, index) in newsList" :key="index" :data-title="item.title" >
                        <div style="padding-bottom: 16px;display: flex;" @click="detail(item)">
                              <div style="width: 24%;">
                                    <img :src="item.flatUrl"  class="news_img">
                              </div>
                              <div style="padding-left: 16px;position: relative;width: 76%;" >
                                    <div class="news_name">
                                        {{item.title}}
                                    </div>
                                    <div class="news_description" v-html="item.details">
                                 
                                    </div>
                              </div>
                        </div>
                    </div>
                </van-col>
            </van-row>

            <div style="margin-bottom: 30px;height: 30px;">

            </div>
      </div>
  </div>


  <van-row justify="center" >
        <van-col  :span="24" >
            <DownLoadApply/> 
        </van-col>
  </van-row>
  
  <!-- 底部    -->
  <van-row justify="center" >
      <van-col  :span="24" >
          <nav-bar />
      </van-col>
  </van-row>
</template>

<script>
  import NavBar from '@/components/NavBar'
  import HeaderGame from '@/components/HeaderGame'
  import { reactive,toRefs ,onMounted} from "vue";  //nextTick
  import DownLoadApply from '@/components/DownLoadApply';
  import { getNewsList } from "@/service/news";
  import { useStore } from 'vuex';

  export default{
      setup(){
        const store = useStore() 
        const state = reactive({
              detailUrl: 'https://html5.gamemonetize.co/hsifddwcxl1md65ndk98nuspks1d4613/',
              title: '',
              html: '',
              id: null,
              newsList:[],
              adsenseCode: `
                                <ins class="adsbygoogle"
                                    style="display:block"
                                    data-ad-client="ca-pub-8751972790679097"
                                    data-ad-slot=""
                                    data-ad-format="auto"
                                    data-full-width-responsive="true">
                                </ins>
                            `
           
          });
          //切换视频
          const cut = ()=>{
             console.log('切换视频')
          }


          //获取数据
          const list = async ()=>{
            let  result = await   getNewsList({
                lang: store.state.app.lang
            })
            if(result){
                state.title = result.data.title;
                state.html = result.data.details;
                state.id = result.data.id;
                state.newsList = result.data.newsMessas;
            }
          }
          //获取数据
          const detail = async (row)=>{
                let  result = await   getNewsList({
                    lang: store.state.app.lang,
                    id: row.id
                })
                if(result){
                    state.title = result.data.title;
                    state.html = result.data.details;
                    state.id = result.data.id;
                    state.newsList = result.data.newsMessas;
                }
          }

          onMounted(() => {
             list();
                console.log("初始化")

              // window.adsbygoogle.push({}); //初始化广告
               //动态加载js文件
               // 脚本控件
             //  const adsbygoogle = window.adsbygoogle || [];
              // adsbygoogle.push({});
           });

          return {
              ...toRefs(state),
              cut,
              detail
          };
      },
      components: {
           NavBar,
           HeaderGame,
           DownLoadApply
      }
  }
</script>
<style>
    .gameframe {
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
    }
    .sKHSJ {
        font-size: 20px;
        line-height: 0.6rem;
        padding: 28px;
        padding-bottom: 27px;
        font-weight: 600;
        font-family: Microsoft Yahei, 微软雅黑, 宋体;
        -webkit-font-smoothing: antialiased;
    }
    ._18p7x{
        border-bottom: 1px solid #f2f2f2;
    }

   ._2kCxD p {
       /* font-size: 16px;*/
        color: #222;
        line-height: 21px;
        padding: 10px;
    }
    ._1NCGf {
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        width: 100%;
    }
    ._2kCxD p img {
        width: 100%;
        border-radius: 10px;
    }
    ._3hMwG ._2kCxD{
        margin-top: 22px;
    }
    .material-symbols-outlined {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        color: #626675;;
        -webkit-font-smoothing: antialiased;
    }
    .news {
       padding: 15px;
    }
    .news_img {
        width: 100px;
        height: 100px;
        overflow: hidden;
        border-radius: 10px;
        object-fit: cover;
    }
    .news_name {
        margin-top: 3px;
        font-size: 16px;
        font-weight: 900;
        color: #000;
        line-height: 21px;
        -webkit-line-clamp: 2;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
    .news_description {
        margin-top: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #3b3b3b;
        line-height: 20px;
        -webkit-line-clamp: 2;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
    ._1i_Oe{
        border-radius: 10px;
    }
    /* 自定义样式 */
   /*.ad-container {
        margin: 20px;
  
        padding: 10px;
        text-align: center;
   }*/
</style>