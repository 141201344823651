<template>
    <div class="game-content"  style="width:100%;height: calc(var(--vh, 1vh) * 95);">
        <div class="tab-content" style="width:100%;">
            <van-row justify="center" >
                <van-col :span="24">
                   <!--  放入视频组件 -->
                   <video-player-wrapper :episodes="episodes" ref="video" :selectedEpisode="selectedEpisode" :playletId="playlet" @pause-button="suspendFun"   @play-back="playBack"> </video-player-wrapper>
                </van-col>
            </van-row>

            <!-- 集数-->
            <van-row  justify="center">
                <van-col :span="24">
                    <!-- 放入集数组件 -->
                    <episode-categories :total-episodes="total" :plaName="plaName" :plaIcon="plaIcon" :tag="tag" :isDrama="isDrama" ref="episodeCategorie" @binge-watch="watchEvent"  @custom-event="handleCustomEvent"  @close-xjdt="closeXjdt"/>
                </van-col>
            </van-row>

            <!-- 选集跟上一集下一集-->

            <van-row justify="center">
                <van-col :span="24">
                    <!-- 关闭按钮 -->
                    <div class="layerCloseButton_container__ENx95">
                        <div class="layerCloseButton_btnBg__p1vOU" @click="closePlay">
                            <span class="material-symbols-outlined">
                                close
                            </span>
                        </div>
                    </div>
                    <!-- 上一页下一页 -->
                    <div class="slideButton_btnWrap__kom+B slideButton_class-layer-feed__KSpWt test" style="top: calc(50% - 32px); transform: translateY(-50%); right: 30px;">
                        <div class="slideButton_btn__DRYwm slideButton_btnPre__PeSzH" @click="lastEpisode">
                            <div class="shortVideoItem_imgWrap__CAyzh">
                                <span class="material-symbols-outlined">
                                    arrow_upward
                                </span>
                            </div>
                        </div>
                        <div  class="slideButton_btn__DRYwm slideButton_btnNext__xpGaJ" @click="nextEpisode">
                            <div class="shortVideoItem_imgWrap__CAyzh">
                                <span class="material-symbols-outlined">
                                    arrow_downward
                                </span>
                            </div>
                        </div>
                    </div>
                   <!--   选集  -->
                    <div class="slideButton_btnWrap__kom+B slideButton_class-layer-feed__KSpWt test" style="top: calc(93% - 32px); transform: translateY(-50%); right: 30px;">
                        <div class="" @click="openWorks">
                            <span class="material-symbols-outlined" >
                                more_horiz
                            </span>
                        </div>
                    </div>
     

                <!--  控制按钮显示播放 -->
                    <div class="big-play-pause iqp-pop-keyboard" v-if="suspend">
                        <div class="vjs-big-play-button">
                            <span class="play-circle" style="font-size: 70px;">
                                play_circle
                            </span>
                        </div>
                    </div> 
                </van-col>
            </van-row>


             <!-- 底部    -->
             <van-row justify="center" >
                <van-col  :span="24" >
                    <nav-bar />
                </van-col>
            </van-row>

        </div>
    </div>
</template>
<script>
      import NavBar from '@/components/NavBar'
      import {ref, reactive,toRefs,provide,onMounted ,nextTick} from "vue";  //nextTick
      import VideoPlayerWrapper  from '@/components/VideoPlayerWrapper'
      import EpisodeCategories from '@/components/EpisodeCategories';
      import { useStore } from 'vuex'
      import { useRouter,useRoute } from "vue-router";
      import { episodesList } from "@/service/playlet";
      import { Toast  } from "vant";
      import { bingeWatch,cancelWatch } from "@/service/playlet";
      export default{
            setup(){
                const router = useRouter();
                const store = useStore();
                //子组件选集调用方法
                const episodeCategorie = ref(null);
                //子组件视频组件调用内部方法
                const video = ref(null);
                const route = useRoute(); //获取url上的参数
                const state = reactive({
                    playlet: Number( route.query.id) , //短剧ID
                    selectedEpisode: 1, //  当前播放集数
                    total: 0,
                    episodes: [

                    ],
                    plaIcon: '',
                    plaName:'',
                    tag:'',
                    isDrama: '',
                    suspend: false
                })
                //给子组件提供数据
                provide('store', store);
                //监听子组件点击方法
                const handleCustomEvent  = (data)=>{
                    //根据选好集数显示指定视频
                    state.selectedEpisode = data;
                    nextTick(()=>{
                        //加载谷歌登录资源
                        video.value.playVideo();
                    });
                }
                //播放完成 跟滑动事件,自动播放
                const playBack = (data)=>{
                    //播放完成自动播放下一集
                    state.selectedEpisode = state.selectedEpisode + data;
                    //加载谷歌登录资源
                    video.value.playVideo();
                }
                //关闭页面跳转到首页
                const closePlay = ()=>{
                    router.push({ path: '/shortDrama/playlet', query: ""})
                }
                //打开选集
                const openWorks = ()=>{
                    //暂停视频
                    video.value.suspend();
                    //打开选集
                    episodeCategorie.value.openAntholigy();

                    episodeCategorie.value.whenSelected(state.selectedEpisode);
                }
                //当用户关闭了选集弹框
                const closeXjdt = ()=>{
                    //恢复视频播放
                    video.value.playVideo();
                }
                //上一集
                const lastEpisode = ()=>{
                    if(state.selectedEpisode -1 <= 0){
                        Toast("已经是第一集了！！！")
                        return;
                    }
                    state.selectedEpisode =  state.selectedEpisode -1;
                  //  video.value.playVideo();
                }
                //下一集
                const nextEpisode = ()=>{
                    if(state.selectedEpisode + 1 > state.total ){
                        Toast("已经是最后一集了！！！")
                        return;
                    }
                    state.selectedEpisode = state.selectedEpisode + 1;
       
                    //获取到下标为数组最后一个元素时，不进行播放
                 //   console.log('开始播放下一集')
                  //  video.value.playVideo();

                }

                //从后台获取数据
                const epis = async ()=>{
                    let result = await episodesList(route.query.id);
                    if(result){
                        state.episodes = result.list.playletEpisode;
                        state.total =  result.list.plaTotal;
                        state.plaIcon = result.list.plaIcon;
                        state.plaName = result.list.plaName;
                        state.tag = result.list.tag;
                        state.isDrama = result.list.isDrama;
                    }
                }
                //监听加入加剧按钮
                const watchEvent = async (data)=>{
                   //判断是否登录，需要登录才能添加到追剧里面
                   var isLogfinf =  store.getters.isLogin ? true : false;
                   if(isLogfinf){
                       if(data == 1){
                            //调用接口
                            let result = await bingeWatch({
                                userId:   store.getters.userId,
                                playletId: state.playlet
                            })
                            if(result.code == 0){
                                state.isDrama = true;
                            }
                       }
                       if(data == 2){
                             let result = await cancelWatch({
                                userId:   store.getters.userId,
                                playletId: state.playlet
                            })
                            if(result.code == 0){
                                state.isDrama = false;
                            }
                       }
    
                    }else{
                        Toast("需要登录后才能添加到追剧列表");
                        store.commit("setNeedLogin", true); 
                    }
                }
                //提供给子组件，显示暂停按钮
                const suspendFun = (data)=>{
                    state.suspend = data;
                }
                //获取缓存中的记录，获取播放最新一集数量
                const gainCache = ()=>{
                    //根据短剧ID获取缓存中所有集数，然后获取索引最大那集，二次播放直接定位
                    var records =  store.state.app.records;
                    var sort = 1; //默认从第一集开始，没获取到缓存
                    for(var i = 0; i < records.length; i++){
                       var item = records[i];
                       if(item.playletId == state.playlet){
                            if(sort <= item.episId){
                                sort = item.episId;  
                            }
                       }
                    }
                    //赋值给选集，变动后，视频会自带刷新
                    state.selectedEpisode = sort;      
                }


                onMounted(() => {
                    //获取数据
                    epis();
                    //根据播放记录获取最新一集id
                    gainCache();
                });

                return {
                    ...toRefs(state),
                    handleCustomEvent,
                    playBack,
                    closePlay,
                    openWorks,
                    episodeCategorie,
                    video,
                    closeXjdt,
                    lastEpisode,
                    nextEpisode,
                    watchEvent,
                    suspendFun
                }
            },
            components: {
                NavBar,
                VideoPlayerWrapper,
                EpisodeCategories
            }
      }
</script>
<style>
    .layerCloseButton_container__ENx95 {
        -webkit-app-region: no-drag;
        align-items: center;
        display: flex;
        left: 10px;
        position: absolute;
        top: 40px;
        z-index: 20;
    }
    .layerCloseButton_btnBg__p1vOU {
        align-items: center;
        background: #01050d80;
        border: 1px solid #ffffff26;
        border-radius: 32px;
        cursor: pointer;
        display: flex;
        height: 40px;
        justify-content: center;
        width: 40px;
    }
    .material-symbols-outlined {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        color: rgba(228, 232, 238, 0.836);
        -webkit-font-smoothing: antialiased;
    }
    .slideButton_btnWrap__kom\+B {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 52px;
        top: 92px;
        width: 32px;
        z-index: 20;
    }
    .slideButton_btnPre__PeSzH {
        border-radius: 16px 16px 0 0;
    }
    .slideButton_btnNext__xpGaJ {
    border-radius: 0 0 16px 16px;
}
    .slideButton_btn__DRYwm {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: #45484d;
        height: 40px;
        line-height: 40px;
        opacity: 0.8;
        text-align: center;
        width: 32px;
    }
    .shortVideoItem_imgWrap__CAyzh {
        border-radius: 12px;
        height: calc(100% - 4px);
        overflow: hidden;
        position: absolute;
        width: calc(100% - 2px);
    }
    .big-play-pause{
        left: 50%;
        margin-bottom: 20px;
        top: 50%;
        transform: translate(-50%,-75%);
    }
    .iqp-pop-keyboard{
        align-items: center;
        display: flex;
        font-size: 0;
        justify-content: center;
        left: 50%;
        pointer-events: none;
        position: absolute;
        text-align: center;
    }

    .play-circle{
        font-family: 'Material Icons';
        border-radius: 50%;
        color: rgba(228, 232, 238, 0.836);
    }
</style>