<template>
  <div class="nav-bar van-hairline--top">
    <ul class="nav-list">
     
    <!--   <router-link tag="li" class="nav-list-item active" :to="{path: '/news/windzxsh',title: '资讯'}">
        <van-icon name="more-o" />
        <span>资讯</span>
      </router-link> -->
      <router-link tag="li" class="nav-list-item active" :to="{path: '/mini/OnlineMinigames',title: '单机游戏'}">
        <van-icon name="more-o" />
        <span>单机游戏</span>
      </router-link>
      <router-link tag="li" class="nav-list-item active"  :to="{path: '/singleGame/ConsoleGame',title: '在线小游戏'}" >
        <van-icon class-prefix="my-icon" name="game" />
        <span>在线小游戏</span>
      </router-link>

      <router-link tag="li" class="nav-list-item active" to="/">
        <van-icon name="https://handzonegame.com/img/common/OL-game.png" alt="" />
        <span>网游</span>
      </router-link>
    
     <!-- <router-link tag="li" class="nav-list-item" :to="{path: '/my'}">
        <van-icon  name="manager-o"/>
        <span>{{ $t("LAN.mine") }}</span>
      </router-link>  -->

      <router-link tag="li" class="nav-list-item" :to="{path: '/shortDrama/playlet',title:'短剧'}">
          <van-icon name="video-o" />
          <span>短剧</span>
        </router-link>

       <router-link tag="li" class="nav-list-item" :to="{path: '/zwsjbx',title:'创收'}">
          <van-icon name="gold-coin-o" />
        <span>创收</span>
      </router-link> 
    </ul>
  </div>
</template>

<style lang="less" scoped >
@import "../style/mixin";
.nav-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 5px 0;
  background: #fff;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  border-top: 1px solid #ebedf0;
  .nav-list {
    width: 100%;
    .fj();
    flex-direction: row;
    padding: 0;
    .nav-list-item {
      display: flex;
      flex: 1;
      flex-direction: column;
      text-align: center;
      color: #666;
      &.router-link-active {
        color: @primary;
      }
      i {
        text-align: center;
        font-size: 22px;
      }
      span {
        font-size: 12px;
      }
    }
  }
}

@media screen and (min-width: 640px) {
  .nav-bar {
    font-size: 100px !important;
    max-width: 640px;
    left:0;
    right:0;
    margin:0 auto;
  }
}
</style>
