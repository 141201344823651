<template>
  <div class="game-content"  style="width:100%;height: calc(var(--vh, 1vh) * 95);overflow-y: scroll;">
    <div class="tab-content" style="width:100%;">
      <!-- 头部  --> 
        <!-- <div style="width: 98%;display: flex;" >
        <div style=" height: 35px;" >
            <img  style=" height: 35px;" src="/img/login/logo_6.png" />
        </div>  
      </div>  --> 

      <van-row justify="space-between">
        <van-col :span="24">
            <HeaderGame/>
        </van-col>
      </van-row>

      <van-row style="margin-top: 10px;">
        <van-col :span="24">
            <van-tabs v-model="active" color="#6387ea" title-active-color="#6387ea" background="rgba(165, 42, 42, 0)" >
 
                <!-- 轮播图 -->
                <van-swipe :autoplay="3000" >
                        <van-swipe-item  v-for="(banner, index) in bannerList" :key="index">
                            <img class="img"  :src="banner.imageUrl"  alt="" @click="onStartGame(banner.gameId)" />
                    </van-swipe-item>
                </van-swipe>

                <van-tab>
                    <template #title  > 
                        <!-- <van-icon class="van_tab_font" name="game"/>  --> 
                        <div class="van_tab_font" name="game"  >
                            {{ $t("LAN.top_free") }}
                        </div>
                    </template>
                        <div >
                            <ul class="game-list" v-if="gameList.length">
                                <li v-for="(game, index) in gameList" :key="index" >
                                    <img :src="game.imageUrl" alt="" class="game-img" />
                                    <div class="txt" @click="onpeLdy(game.id)">
                                          <!--  优化标题 -->
                                        <div class="txt-title">
                                            <h1 style="font-size:0.38647rem;">
                                                {{ game.title }}
                                            </h1>
                                            <!-- <van-tag v-for="(tag, tagindex) in game.tags" :key="tagindex" class="tag" :color="tag.color">{{ tag.label }}</van-tag> -->
                                        </div>
                                        <!--  <p class="txt-gray">{{ game.content }}</p> -->
                                        <div class="txt-gray">
                                            {{ game.content }}
                                        </div>
                                    </div>
                                        <!-- <div class="button">
                                        <a type="button" class="button-start" @click="onStartGame(game)">{{ $t("LAN.start_game") }}</a>
                                        <a v-if="game.downloadUrl.length" type="button" class="button-start" @click="onDownLoad(game.downloadUrl)">{{ $t("LAN.game_down") }}</a>
                                    </div>  -->
                                </li>

                                <li>
                                    <div class="txt" style="height: 50px;">
                                        <div class="txt-title">
                                            <h1 style="font-size:0.38647rem;">
                                      
                                            </h1>
                                            <!-- <van-tag v-for="(tag, tagindex) in game.tags" :key="tagindex" class="tag" :color="tag.color">{{ tag.label }}</van-tag> -->
                                        </div>
                                        <!--  <p class="txt-gray">{{ game.content }}</p> -->
                                        <div class="txt-gray">
                                
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <van-empty :description="$t('LAN.empty')" v-else/>
                        </div>             
                </van-tab>  

                <van-tab>
                    <template #title> 
                        <!-- <van-icon name="notes-o"/> 新游 -->
                        <div class="van_tab_font" name="game" >
                            {{ $t("LAN.top_jackpot") }}
                        </div>
                    </template>
                    <van-empty :description="$t('LAN.empty')"/>
                </van-tab> 

                </van-tabs>

        </van-col>
      </van-row>
    </div>

  </div>

    <!-- 下载按钮  -->
    <!--  <van-row justify="center" v-if="installShow">
        <van-col  :span="24" >
            <div id="downGameBox">
                <div class="tipAddScreen">
                    <div class="tipBox">
                        <div class="tip" style="display: flex;">
                            <div class="x7Icon">
                                <img src="/img/common/xz.png" alt="">
                            </div>
                            <div  class="text"> 下载到桌面 </div>
                        </div>
                    </div>
                </div>
            </div>
        </van-col>
    </van-row> 

     <van-row  justify="center">
        <van-col :span="24">
            <van-popup  v-model:show="showBottom"  round  position="bottom" style="height: 43%;" >
                <div class="top">
                    <img src="../../public/img/icons/apple-touch-icon-152x152.png" alt="">
                    <div class="top_box">
                        <div class="title"> 快速添加桌面应用 </div>
                        <div class="second_title"> 便捷启动·全屏体验 </div>
                    </div>
                    <div class="close">
                        <img @click="closeBottom" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABUCAMAAAArteDzAAAAjVBMVEUAAABmZmZmZmZra2tnZ2diYmJmZmZmZmZmZmZkZGRlZWVlZWVkZGRmZmZoaGhmZmZhYWFlZWVkZGRmZmZkZGRnZ2dnZ2dmZmZlZWVlZWVmZmZmZmZmZmZmZmZkZGRoaGhnZ2dlZWVmZmZlZWVmZmZmZmZmZmZmZmZlZWVlZWVlZWVmZmZnZ2doaGhqamo6s1tYAAAAK3RSTlMA+bkSVwbJfUEhFPSl4WAZC+ibaUXQDs5fVvC+7GQ3KxydkW+I3NrRsHRT+hcGAAAAAlNJREFUWMPtmNmOm0AURMHNbo/3AAYMeJm1l///vBDLUVC6pVIR52Ek12v7Xvc5lnAJ75lnvlnCdZ6Hj57sg8uxW09Zmn59lp1za3Kui2gRZPTK9W3yVAqboD9LbbSpA8GyJyd/GJRRa39dUGs1xI/Iu4b7xVwOg2ZzXP19ll8Ko6SU2p8tM4p9sbkNmipOraXHSCs5ROlZIAj27dzIXzHNJbVOu4VRt1PLAGS/TZnXJHRJ9bWUlgHIfud7j3vPThZEvpIjAwS78l/ilfMzIpjpkQGGXb/sUs+dbDkbGyDYf9i/PDbAs2MDPDs2wLNjAzw7NsCzYwM0O28As/MGMDtvALPzBjA7bwCz8wYwO28As/MGBGCfZkB4IWTnDWSInTcwL64fgJ03oORbowE7b0Bqhdh5A0MAOxGxnM3VaGnhZOfvWvxZqTZudt7r9U3Lu9dq2z6mtScfzW9+7dfL/CE7t3M9cgr6ANnr5Og5AEJ0m9Fz4J/Zzf2KlS9BH2DZldpsa6WAAZa9iNvhOYAM8J02x42I7bTO/wIc3G0EMDCtzwtkgO/z2ADf57EBnh0b4NmxAZ4dG+DZCQM0OzbAs7sNlGIKOzJwyHh2bOAgWHbeAGYnDfDsvAG+z4tybMB51+REd1pxGBvIbfb07POdNjuMDLShBf9VA3ZgwDSON2ifhVYWO2HAVHFnLS0joyx2woCpdr3ltDtJM+TdwY4NDIOq2K/tX6qMNlXzemfn7ho1VVVcXZOiPcaXpPf45MPkbr8KnYerNJ34TnrV9WvvmWf+V34Cl8HBtcvy2fIAAAAASUVORK5CYII=" alt="">
                    </div>
                </div>

                 <div class="bottom">
                    <van-swipe :autoplay="3000"  @change="onChange">
                        <van-swipe-item>
                            <img src="http://down1.msshuo.cn/x7sy/app/opera/i18n_language/content_file/1728358253829_1724320575853_tutorials_GIF1.gif" alt="" class="my-swipe_gif">
                        </van-swipe-item>
                        <van-swipe-item>
                            <img src="http://down1.msshuo.cn/x7sy/app/opera/i18n_language/content_file/1728358373468_1724323437526_tutorials_GIF2.gif" alt="" class="my-swipe_gif">
                        </van-swipe-item>
                        <van-swipe-item>
                            <img src="https://down1.msshuo.cn/x7sy/app/opera/i18n_language/content_file/1728358440834_1724323536190_tutorials_GIF3.gif" alt="" class="my-swipe_gif">
                        </van-swipe-item>
                    </van-swipe>

                    <div class="current">{{ stepNumber }}</div>
                 </div>
            </van-popup>
        </van-col>
    </van-row>  -->

    <DownLoadApply/>

    <!-- 底部    -->
    <van-row justify="center" >
        <van-col  :span="24" >
            <nav-bar />
        </van-col>
    </van-row>

</template>

<script>
import { reactive, onMounted, toRefs } from "vue";
import { useRouter,useRoute } from "vue-router";
import NavBar from '@/components/NavBar'
import { Toast } from 'vant'
import { requestBanner, requestGameList,packname } from "@/service/web";
import { validatenull } from '@/utils/validate';
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import HeaderGame from '@/components/HeaderGame'
import DownLoadApply from '@/components/DownLoadApply'


  
export default {
  name: "game",
  setup() {
    const router = useRouter();
    const store = useStore();
    const route = useRoute(); //获取url上的参数
    const { t } = useI18n()
    const state = reactive({
       bannerList: [],
       gameList:[],
       loginState: false,
    });

    onMounted(() => {
        t('');
        init();
        //监听
        install();
        //登录判断
        alreadyLogged();
    });

    const init = async () => {
        //获取缓存里面test的值
        if(route.query.sandbox){
            store.commit("setSandbox", route.query.sandbox)
        }
        if(route.query.test){
            store.commit("setTest", route.query.test)
        }
        if(route.query.gameId){
            store.commit("setGameId", route.query.gameId)
        }
        // Toast.loading('加載中');
        store.commit("setNeedLogin", false);
        let result = await requestBanner();
        if (result) {
            state.bannerList = result.bannerList;
            result = await requestGameList(store.state.app.test,store.state.app.gameId);
            state.gameList = result.gameList;
            for (const game of state.gameList) {
                game.tags = [];
                if (!validatenull(game.label)) {
                    let list = game.label.split('|');
                    for (let i = 0; i < list.length; i++) {
                        let label = list[i].split(',');
                        game.tags.push({
                          color:label[0],
                          label:label[1],
                        })
                    }
                }
            }
         
        }
        Toast.clear()
    };

    const onStartGame = (game) => {
        console.log(game)
          /* if(game.accountSystem == 0){
                const isLogin =  store.getters.isLogin;
                if(isLogin){
                    console.log(store.state.user.token)
                    goTo("/gameplay/",{
                        id:  game.id,
                        token: store.state.user.token,
                        username: store.state.user.username,
                        uid: store.state.user.userId
                })
                }else{
                    store.commit("setNeedLogin", true); 
                }
           }else{
            goTo("/gameplay/",{
                    id:  game.id
                })
           }*/
        goTo("/ldy/squeezePlay",{
            id:  game
        })
    }

    const onDownLoad = (url) => {
            const isLogin =  store.getters.isLogin;
            if(!isLogin || isLogin == "false"){
                store.commit("setNeedLogin", true)
            }else{
                window.open(url);
            }
    }

    const goTo = (url, params) => {
        router.push({ path: url, query: params})
    }
    //跳转落地页
    const onpeLdy = async  (id)=>{
            // 获取落地页表
        let result = await packname(id);
        if(result.data){
            goTo("/store/apps/details",{
                id:result.data
            })
        }
    }
   /* const  getCookie = function(cname) {
         var name = cname + "=";
         var ca = document.cookie.split(';');
         for (var i = 0; i < ca.length; i++) {
             var c = ca[i];
             while (c.charAt(0) == ' ') c = c.substring(1);
             if (c.indexOf(name) != -1) {
                 return c.substring(name.length, c.length);
             }
         }
         return "";
     }*/
  

    //控制安装按钮是否显示
    const install = ()=>{
        //异步执行检测
        state.installShow = true;
    }
    //拉起登录弹框
     const login = ()=>{
        store.commit("setNeedLogin", true); 
    }
    const skipUserCenter = ()=>{
        goTo("/my",{});
    }
    //判断是否已登录
    const alreadyLogged = ()=>{
        var isLogin =  store.getters.isLogin;
        state.loginState = isLogin ? false : true;
    }


    return {
      ...toRefs(state),
      onStartGame,
      onDownLoad,
      onpeLdy,
      login,
      skipUserCenter
    };
  },
  components: {
     NavBar,
     HeaderGame,
     DownLoadApply
  },
};
</script>

<style lang="less" scoped>
.game-content {
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  //  overflow-y: scroll;
}

.img {
  //width: 99%;
  border-radius: 20px;
 // height: 249.02px;
 width: 99%;  
 height: 100%; 
  border: solid 2px rgba(190, 180, 180, 0.3);
}

.van-tab .van_tab_font {
    font-size: 20px;
    font-weight:bold;
}


   
.game-list {
    //  background: #fff;
      cursor: pointer;
      position: relative;
     // margin-top: 10px;
      padding: 10px;
      height: calc(var(--vh, 1vh) * 100 - 50px);
      li {
          overflow: hidden;
          padding: 5px;
          border-bottom: 1px solid #f1f1f1;
          display: flex;
          display: -webkit-flex;
         // align-items:center;
          justify-content:center;
          &:last-child {
              border-bottom: 0px;
          }

          .game-img {
              width: 60px;
              height: 60px;
              margin-right: 10px;
              border-radius: 10px;
              border: solid 2px rgba(190, 180, 180, 0.3);
          }

          .txt {
            text-align: left;
            width: 300px;
           // line-height: 20px;
            .txt-title {
                font-size: 16px;
                color: #333;
                //margin: 0 0 5px;
                .tag {
                    margin-left: 5px;
                }
            }
            .txt-gray {
                color: #999;
                font-size: 13px;
            }
          }

          .button {
              width: 50px;
              line-height: 25px;
          }

          .button-start {
              display: inline-block;
              width: 50px;
              background: #6e87ea;
              color: #fff;
              font-size: 16px;
              text-align: center;
              border-radius: 5px;
              line-height: 30px;
              margin-top: 5px;
          }

      }
  }

</style>