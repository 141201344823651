<template>
    <div class="game-content"  style="width:100%;height: calc(var(--vh, 1vh) * 95);overflow-y: scroll;">
        <div class="tab-content" style="width:100%;">
            <!-- 头部    -->
            <van-row justify="center" >
                <van-col  :span="24" >
                    <HeaderGame />
                </van-col>
            </van-row>

             <!--   <van-row justify="center">
                <van-col  :span="24" style="margin-top: 50%;" >
                  <van-empty description="功能暂未开放，敬请期待！" /> 

                </van-col>
            </van-row> -->

            <van-row justify="center" >
                <van-col :span="24">
                    <van-tabs @click-tab="switchTab">
                        <van-tab title="推荐">
                           <div class="flex-resp_cardRespRows__zGN4P" style="margin-bottom: 20px;">
                                <div  v-for="(item, index) in playlet" :key="index">
                                    <div class="flex-resp_cardRespRow__uSx4C" style="height: auto;margin-top: 20px;"  v-if="index % 2 == 0">
                                        <div class="flex-resp_cardRespItem_4__jA5B8 flex-resp_cardRespItem__vv85j"  >
                                            <div>
                                                <div class="film-feed_filmFeed__alGO3 flex-tiles-item_filmFeed__vTvrx film-feed_noFeedwrap__GCuGT"  >
                                                    <div class="film-feed_cover__NzNEK" style="height: 100%;"></div>
                                                    <div class="film-feed_innerwrap__Ky0a8"  @click="goToDetail(item)">
                                                        <div class="film-feed_imgwrap__jaaM4 film-feed_onlyPlayer__EWvQq flex-tiles-item_imgwrap__z6mBp" style="height: 242px;">
                                                            <img :src="item.plaIcon" class="film-feed_noHovering__a6ov7 film-feed_img__VZYLF film-feed_noFeed__cHQ94" alt="" style="height: 100%;">
                                                            <div class="flex-video-list_update_info__yEOcf film-feed_update_info__f2u8S">
                                                                <span data-content="98集全" class="flex-video-list_update_info_text__FsUAU">
                                                                    <span class="card-memos_update_info_text__M8ybR"> {{ item.plaTotal }}集全 </span>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div style="position: relative;">
                                                            <div>
                                                                <div class="ffilm-feed_content2__2QvkN">
                                                                    <div class="flex-video-list_titles__4zNl1">
                                                                        {{item.plaName}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex-resp_cardRespItem_4__jA5B8 flex-resp_cardRespItem__vv85j"   v-if="playlet[index+1]">
                                            <div>
                                                <div class="film-feed_filmFeed__alGO3 flex-tiles-item_filmFeed__vTvrx film-feed_noFeedwrap__GCuGT"   >
                                                    <div class="film-feed_cover__NzNEK" style="height: 100%;"></div>
                                                    <div class="film-feed_innerwrap__Ky0a8"  @click="goToDetail(playlet[index+1])">
                                                        <div class="film-feed_imgwrap__jaaM4 film-feed_onlyPlayer__EWvQq flex-tiles-item_imgwrap__z6mBp" style="height: 242px;">
                                                            <img :src="playlet[index+1].plaIcon" class="film-feed_noHovering__a6ov7 film-feed_img__VZYLF film-feed_noFeed__cHQ94" alt="" style="height: 100%;">
                                                            <div class="flex-video-list_update_info__yEOcf film-feed_update_info__f2u8S">
                                                                <span  class="flex-video-list_update_info_text__FsUAU">
                                                                    <span class="card-memos_update_info_text__M8ybR"> {{ playlet[index+1].plaTotal }}集全 </span>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div style="position: relative;">
                                                            <div>
                                                                <div class="ffilm-feed_content2__2QvkN">
                                                                    <div class="flex-video-list_titles__4zNl1">
                                                                        {{playlet[index+1].plaName}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        
                                    </div> 
                                </div>

                            </div>     
                        </van-tab>
                        <van-tab title="我在追">
                            <div class="flex-resp_cardRespRows__zGN4P" style="margin-bottom: 20px;" v-if="haveData">
                                <div  v-for="(item, index) in playlet" :key="index">
                                    <div class="flex-resp_cardRespRow__uSx4C" style="height: auto;margin-top: 20px;"  v-if="index % 2 == 0">
                                        <div class="flex-resp_cardRespItem_4__jA5B8 flex-resp_cardRespItem__vv85j"  >
                                            <div>
                                                <div class="film-feed_filmFeed__alGO3 flex-tiles-item_filmFeed__vTvrx film-feed_noFeedwrap__GCuGT"  >
                                                    <div class="film-feed_cover__NzNEK" style="height: 100%;"></div>
                                                    <div class="film-feed_innerwrap__Ky0a8"  @click="goToDetail(item)">
                                                        <div class="film-feed_imgwrap__jaaM4 film-feed_onlyPlayer__EWvQq flex-tiles-item_imgwrap__z6mBp" style="height: 242px;">
                                                            <img :src="item.plaIcon" class="film-feed_noHovering__a6ov7 film-feed_img__VZYLF film-feed_noFeed__cHQ94" alt="" style="height: 100%;">
                                                            <div class="flex-video-list_update_info__yEOcf film-feed_update_info__f2u8S">
                                                                <span data-content="98集全" class="flex-video-list_update_info_text__FsUAU">
                                                                    <span class="card-memos_update_info_text__M8ybR"> {{ item.plaTotal }}集全 </span>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div style="position: relative;">
                                                            <div>
                                                                <div class="ffilm-feed_content2__2QvkN">
                                                                    <div class="flex-video-list_titles__4zNl1">
                                                                        {{item.plaName}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex-resp_cardRespItem_4__jA5B8 flex-resp_cardRespItem__vv85j"   v-if="playlet[index+1]">
                                            <div>
                                                <div class="film-feed_filmFeed__alGO3 flex-tiles-item_filmFeed__vTvrx film-feed_noFeedwrap__GCuGT"   >
                                                    <div class="film-feed_cover__NzNEK" style="height: 100%;"></div>
                                                    <div class="film-feed_innerwrap__Ky0a8"  @click="goToDetail(playlet[index+1])">
                                                        <div class="film-feed_imgwrap__jaaM4 film-feed_onlyPlayer__EWvQq flex-tiles-item_imgwrap__z6mBp" style="height: 242px;">
                                                            <img :src="playlet[index+1].plaIcon" class="film-feed_noHovering__a6ov7 film-feed_img__VZYLF film-feed_noFeed__cHQ94" alt="" style="height: 100%;">
                                                            <div class="flex-video-list_update_info__yEOcf film-feed_update_info__f2u8S">
                                                                <span data-content="98集全" class="flex-video-list_update_info_text__FsUAU">
                                                                    <span class="card-memos_update_info_text__M8ybR"> {{ playlet[index+1].plaTotal }}集全 </span>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div style="position: relative;">
                                                            <div>
                                                                <div class="ffilm-feed_content2__2QvkN">
                                                                    <div class="flex-video-list_titles__4zNl1">
                                                                        {{playlet[index+1].plaName}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        
                                    </div> 
                                </div>
                            </div>

                            <van-empty v-else description="暂无数据！！！" /> 
                        </van-tab>
                    </van-tabs>
                </van-col>
            </van-row> 
            <van-row justify="center" >
                <van-col  :span="24" >
                    <DownLoadApply/> 
                </van-col>
            </van-row>
            <!-- 底部    -->
            <van-row justify="center" >
                <van-col  :span="24" >
                    <nav-bar />
                </van-col>
            </van-row>
        </div>
    </div>
</template>
<script>
    import NavBar from '@/components/NavBar'
    import { useRouter } from "vue-router";

    import { reactive,toRefs,onMounted } from "vue";  //nextTick
    import { getPlaylet,bingeWatchList } from "@/service/playlet";
    import { Toast  } from "vant";
    import { useStore } from 'vuex';
    import DownLoadApply from '@/components/DownLoadApply';
    import HeaderGame from '@/components/HeaderGame';
    export default{
        name:'playlet', 
        setup(){
            const router = useRouter();
            const store = useStore();
            const state = reactive({
                playlet:[
                ],
                haveData:false
            })

            const goToDetail =   (drama) =>{
                router.push({ path: '/shortDrama/videoDisplay', query: {
                    id: drama.id,
                    episodes: drama.plaTotal
                }})
            }
            //获取推荐列表
            const getList = async ()=>{
                let result = await getPlaylet();
                if(result){
                    state.playlet = result.list;
                }
            }
            //获取追剧列表
            const getBingeWatch = async ()=>{
                //判断是否登录，需要登录才能添加到追剧里面
                var isLogfinf =  store.getters.isLogin ? true : false;
                if(isLogfinf){
                    let result = await bingeWatchList();
                    if(result){
                        state.playlet = result.list;
   
                        state.haveData = state.playlet.length <= 0 ? false : true;
                    }
                }else{
                    Toast("需要登录后才能获取追剧列表");
                    store.commit("setNeedLogin", true); 
                }
            }



            const switchTab =  (data)=>{
                if(data.name == 0){
                    getList()
                }
                if(data.name == 1){
                    getBingeWatch();
                }
            }

            onMounted(() => {
                getList();
            });

            return {
                ...toRefs(state),
                goToDetail,
                switchTab
  
            };
        },
        components: {
             NavBar,
             DownLoadApply,
             HeaderGame
        }
    }
</script>
<style>
    .flex-resp_cardRespRows__zGN4P{
        width: 100%;
    }
    .flex-resp_cardRespRow__uSx4C {
        width: 100%;
        display: flex;
        position: relative;
    }
    .flex-resp_cardRespItem_4__jA5B8 {

        width: calc(43%);
    }
    .flex-resp_cardRespItem_3__qs6yH, .flex-resp_cardRespItem_4__jA5B8, .flex-resp_cardRespItem_5__oYPAl, .flex-resp_cardRespItem_6__QYlj5, .flex-resp_cardRespItem_7__od9yk, .flex-resp_cardRespItem_8__k\+BYS {
        margin-left: 20px;
       /* margin-right: 20px;*/
        position: relative;
    }
    .flex-tiles-item_filmFeed__vTvrx {
        overflow: visible;
        width: 100%;
    }
    .film-feed_filmFeed__alGO3 {
        border-radius: 12px;
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        overflow: hidden;
        position: relative;
       /* width: calc(50% - 8px);*/
    }
    .film-feed_innerwrap__Ky0a8 {
        background-clip: padding-box, border-box;
      /*  background-image: linear-gradient(180deg, #222, #111214), linear-gradient(180deg, #ffffff3d, hsla(0, 0%, 100%, .026), #fff0);*/
        background-origin: padding-box, border-box;
        border: 1px solid #0000;
        border-radius: 12px;
        display: flex;
        filter: blur(0);
        flex-direction: column;
        transition: filter .3s;
        width: 100%;
    }
    .film-feed_imgwrap__jaaM4.film-feed_onlyPlayer__EWvQq {
        overflow: hidden;
    }
    .video-cards_cardContainerCommon__qDnuj div {
        box-sizing: border-box;
    }
    .flex-tiles-item_imgwrap__z6mBp {
        background-image: linear-gradient(5deg, #ffffff0a, #ffffff3d);
    }
    .film-feed_imgwrap__jaaM4 {
        align-items: flex-start;
        aspect-ratio: 16 / 9;
        background-image: linear-gradient(5deg, #ffffff0a, #ffffff3d);
        border-radius: 12px;
        cursor: pointer;
        display: flex;
        position: relative;
        width: 100%;
        z-index: 0;
    }
    .film-feed_noFeedwrap__GCuGT .film-feed_img__VZYLF.film-feed_noHovering__a6ov7 {
        border-radius: 12px;

    }
    .film-feed_img__VZYLF.film-feed_noFeed__cHQ94 {
        object-fit: cover;
    }

    .film-feed_img__VZYLF {
        aspect-ratio: 16 / 9;
        border-radius: 12px 12px 0 0;
        margin: 0;
        width: calc(100% - 2px);
        width: 100%;
    }
    button, fieldset, img {
        border: none;
        margin: 0;
        outline-style: none;
        padding: 0;
    }
    .film-feed_imgwrap__jaaM4 .film-feed_update_info__f2u8S {
        z-index: 2;
    }
    .video-cards_cardContainerCommon__qDnuj div {
        box-sizing: border-box;
    }
    .flex-video-list_update_info__yEOcf {
        background: linear-gradient(180deg, #0000, #00000080);
        border-radius: 0 0 12px 12px;
        bottom: 0;
        height: 40px;
        left: 0;
        position: absolute;
        right: 0;
        text-align: inherit;
    }
    .flex-video-list_update_info_text__FsUAU {
        bottom: 0;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        overflow: hidden;
        padding: 5px 12px 8px 6px;
        position: absolute;
        right: 0;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: fit-content;
    }
    .flex-video-list_update_info_text__FsUAU:after {
        -webkit-text-stroke: 2px #0006;
        content: attr(data-content);
        left: 6px;
        position: absolute;
        top: 5px;
        z-index: -1;
    }


.flex-video-list_titles__4zNl1 {
    cursor: pointer;
    opacity: 1;
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}



</style>