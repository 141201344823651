<template>
    <div class="game-content" v-if="data.empty"  style="background: rgb(248, 248, 248); height: calc(var(--vh, 1vh) * 100);overflow-y: scroll;   /*这个属性使其可移动*/" >
       <!-- 头部 -->
       <van-row justify="space-between"  style=" min-height: 40px;">
            <van-col :span="24">
                   <div class="header-wrap">
                       <header class="header">
                           <div class="header-main">
                               <div class="header-left" style="height: 34px;">
                                   <img src="/img/login/logo_6.png" alt="logo图" > 
                               </div>
                               <div class="header-right">
                                <div style="margin-right: 20px;">
                                    <span class="google-material-icons" >
                                        search
                                    </span>
                                </div>   
                                <div style="margin-right: 20px;">
                                       <router-link tag="li" class="nav-list-item"  :to="{    path: '/helper'   }" >
                                            <span class="google-material-icons" >
                                                help_outline
                                            </span>
                                        </router-link>
                                   </div>
                                   <div class="header-user" >
                    

                                       <img   v-if="data.loginState" alt=""  src="https://fonts.gstatic.com/s/i/productlogos/avatar_anonymous/v4/web-32dp/logo_avatar_anonymous_color_1x_web_32dp.png" @click="login" />
                                  
                                       <img  alt=""   src="https://fonts.gstatic.com/s/i/productlogos/avatar_anonymous/v4/web-32dp/logo_avatar_anonymous_color_1x_web_32dp.png" @click="skipUserCenter" v-else />  
                                   </div>
                               </div>
                           </div>
                       </header>
                   </div>
           </van-col>
       </van-row>

       <van-row justify="center" style="margin-top: 60px;">
           <van-col :span="6">
               <div style="align-items: center;display: flex;">
                   <img :src="data.imageUrl" class="fFmL2e cN0oRe" alt="图标图片"  >
               </div>
           </van-col>
           <van-col :span="15" >
               <div class="Fd93Bb">
                   <h1>
                       <span class="AfwdI">{{ data.gameTitle }}</span>
                   </h1>
               </div>
           </van-col>
       </van-row>

       <van-row justify="center" style="margin-top: 20px;">
           <van-col :span="21" style="display: flex;">
               <!--  判断系统是否配置安装包 -->
               <div class="u4ICaf" v-if="data.install" @click="installApk">
                   <span style="color:  #fff;">安装</span>
               </div>
               <!--  判断是否后配置h5在线玩链接 -->
               <div  class="u4ICaf" v-if="data.playOnline" @click="playOnline">
                   <span style="color:  #fff;">在线玩</span>
               </div>
           </van-col>
       </van-row>

        <!-- 轮播图 -->
        <van-row  justify="center"  style="margin-top: 20px;">
           <van-col :span="21" >
                    <!--    <van-swipe class="my-swipe" :autoplay="3000" :touchable="true"  :loop="false" :show-indicators="false" >
                    <van-swipe-item  v-for="item in data.labUrlList"   :key="item.uid" :style="{width: setImgWidth(item.width)}" >
                        <img :key="item.uid"  :src="item.url" ondragstart="return false;" style="width: auto;height: 200px;display:inline-block;"/>
                    </van-swipe-item>
                </van-swipe> -->
                <van-swipe class="my-swipe" :initial-swipe="data.activeSlide"  :autoplay="3000" :loop="false" :show-indicators="false" :touchable="true" @change="changeSwipe"   >
                    <van-swipe-item  v-for="item in data.labUrlList"  :key="item.uid" :style="setImgWidth(item.width)">
                        <img :src="item.url" :alt="item.alt" :title="item.title" ondragstart="return false;" style="width:100%;height: 200px;border-radius: 8px;"/>
                    </van-swipe-item>
                </van-swipe>
           </van-col>
        </van-row> 

        <!-- ios安装教程 -->
        <van-row justify="center">
            <van-col :span="24">
                <!-- van-dialog 组件 -->
                <van-dialog  v-model:show="data.openiv" style="margin-top: -0.5rem;" show-cancel-button  :showConfirmButton="false" :showCancelButton="false" class="dialogDesc">
                    <!-- 使用默认插槽插入自定义内容 -->
                    <template  #title>
                        <div class="custom-title" style="line-height: 0.4rem;padding: 5px;" >
                            <div style="text-align: center;color:rgb(26, 26, 26) ;flex: 1;font-size: 0.43rem;"> 安装引导 </div>
                            <div style="width: 0.3rem;font-size: 0.5rem;margin-right: 0.2rem;" @click="closeInstall">x</div>
                        </div>
                    </template>
                    <div>
                        <van-swipe  ref="mySwipe" class="my-swipe" :autoplay="3000" indicator-color="rgb:(14,195,250)"  :loop="false" :touchable="true">
                            <van-swipe-item  v-for="item in data.intro"  :key="item.url">
                               <img style="width: 100%;" :src="item.url" alt="" @click="ubuntu" />
                            </van-swipe-item>
                        </van-swipe> 
                    </div>
                </van-dialog>
            </van-col>
        </van-row>

       <!-- 游戏简介 -->
       <van-row justify="center"  style="margin-top: 30px;">
           <van-col :span="21" style="display: flex;">
               <div class="VMq4uf" >
                   <h2> 游戏简介 </h2>
               </div>
               <div style="align-items: center; display: -webkit-flex; margin-left: 30px;">
                   <span class="material-icons" @click="gameIntrodu">
                       arrow_forward
                   </span>
               </div>
           </van-col>
       </van-row>
       <!-- 游戏描述  -->
       <van-row justify="center"  style="margin-top: 20px;">
           <van-col :span="21">
               <div  class="bARER" v-html="data.gameIntro">
           
               </div>

               <div style="font-size: 0.36rem;text-align: left;margin-top: 30px;" >
                   <div>更新日期</div> 
                   <div style="color: rgb(95, 99, 104);">{{ data.lastTime }}</div>
               </div>
           </van-col>
       </van-row>


       <!-- 新功能 -->
       <van-row justify="center"  style="margin-top: 20px;" v-if="data.gameUpdates">
           <van-col :span="21">
               <div class="VMq4uf">
                   <h2> 新功能 </h2>
               </div>
               <div class="VMq4uf-desc" v-html="data.gameUpdates">
               </div>
           </van-col>
       </van-row>

       <!-- 隐私权政策 -->
       <van-row justify="center"  style="margin-top: 20px;">
           <van-col :span="21" style="display: flex;">
               <div class="VMq4uf">
                   <h2> 隐私权政策 </h2>
               </div>
               <div style="align-items: center; display: -webkit-flex; margin-left: 30px;">
                   <span class="material-icons" @click="privacyPolicy">
                       arrow_forward
                   </span>
               </div>
           </van-col>
        </van-row>
        <van-row justify="center"  style="margin-top: 10px;" >
           <van-col :span="21">
               <div data-g-id="description" class="bARER">如需了解有关收集和分享的数据的更多信息，请参阅开发者的隐私权政策</div>
           </van-col>
        </van-row>



       <!-- 展示信息  -->
       <van-row justify="center" style="margin-top: 20px;" >
           <van-col :span="21" style="display: flex; margin-bottom: 20px;    flex-wrap: wrap;">
               <div class="KvNvKe">
                   <a href="https://play.handzonegame.com/privacy/index.html?tab=0" target="_blank" class="QOTGFf">用户协议</a>
               </div>
               <div class="KvNvKe">
                   <a href="https://play.handzonegame.com/privacy/index.html?tab=1" target="_blank" class="QOTGFf">隐私政策</a>
               </div>
               <div class="KvNvKe">
                   <a href="https://play.handzonegame.com/privacy/index.html?tab=2" target="_blank" class="QOTGFf">数据删除</a>
               </div>
               <div class="KvNvKe avtIH">
                   <div class="AJ34ce">
                       <img src="https://ssl.gstatic.com/store/images/regionflags/hong_kong.png" class="GrpFfc">
                       <div class="yVZQTb">
                           香港 (中文（香港）)
                       </div>
                   </div>
               </div>
           </van-col>
       </van-row>
       <!-- 底部   -->
       <van-row justify="center" style="height: 42px;">
           <van-col  :span="24" >
               <nav-bar />
           </van-col>
       </van-row>

       <van-dialog v-model:show="data.dialogVisible" width="480" show-cancel-button  :showConfirmButton="false" :showCancelButton="false" class="dialogDesc" >

           <!-- 自定义标题 -->
           <template #title >
               <div class="custom-title" >
                   <div>
                       <img :src="data.imageUrl" alt="Icon" style="width: 56px; height: 56px; border-radius: 10px;"  />
                   </div>
                   <div style="margin-left: 20px;width: 80%;">
                       <div style="display: flex;  justify-content: space-between;">
                           <div>
                               <h5 class="xzVNx">{{ data.gameTitle }}</h5>
                           </div>

                           <div class="custom-title-close">
                               <van-icon name="cross"  @click="data.dialogVisible = false" />
                           </div>
                       </div>
                       <div style="margin-top: 10px;">
                           <span>游戏简介</span>
                       </div>
                   </div>
           
               </div>
           </template>

           <div style="margin: 10px 20px 1px 20px; height: calc(var(--vh, 1vh) * 50);overflow-y: scroll;">
               <div class="fysCi" >
                   <div v-html="data.gameIntro">
                   </div>
               </div>
               <div class="r7tDJb"></div>
               <div class="G1zzid" style="display: flex;">
                   <div class="sMUprd">
                       <div class="q078ud">版本</div> 
                       <div class="reAt0">因设备而异</div>
                   </div>
                   <div class="sMUprd">
                       <div class="q078ud">更新日期</div> 
                       <div class="reAt0">2024年9月1日</div>
                   </div>
                   <div class="sMUprd">
                       <div class="q078ud">Android 系统版本要求</div>
                       <div class="reAt0">5.0 及更高版本</div>
                   </div>
                   <div class="sMUprd">
                       <div class="q078ud">下载次数</div> 
                       <div class="reAt0">1,000,000+ 次下载</div>
                   </div>

                   <div class="sMUprd">
                       <div class="q078ud">发布日期</div> 
                       <div class="reAt0">2024年10月15日</div>
                   </div>
                   <div class="sMUprd">
                       <div class="q078ud">提供方</div> 
                       <div class="reAt0">Handzone Games</div>
                   </div>
               </div>
           </div>
         <!--   <img src="https://fastly.jsdelivr.net/npm/@vant/assets/apple-3.jpeg" style="width: 100%;height: 100%;"/> -->
       </van-dialog>

       <!-- 隐私政策弹窗 -->
       <van-popup v-model:show="data.priveShow" round :style="{ height: '90%', width: '90%', maxWidth: '440px' }">
           <iframe src="https://play.handzonegame.com/privacy/index.html?lang=zh_TW" style="width: 100%;height: 100%;" frameborder="0"/>
       </van-popup>
 
   
    </div>
    <div v-else style="background: rgb(248, 248, 248); height: calc(var(--vh, 1vh) * 100);" >
        <div>
            <van-empty style="height: calc(var(--vh, 1vh) * 80);"  description="落地页未配置" />
        </div>
      
        <div>
            <nav-bar />
        </div>

    </div>
</template>

<script>
import { reactive, onMounted,toRefs,ref  } from 'vue';
import NavBar from '@/components/NavBar';
import { ldyConfig,gainAccount } from "@/service/web";
import { useRoute ,useRouter} from 'vue-router';
import { useStore } from 'vuex';
import { Toast } from 'vant';
import axios from "axios";


   export default {
       setup (){
           const store = useStore()
           const route = useRoute()
           const router = useRouter();
           const mySwipe = ref(0);
           const state = reactive({
              data:{
                   gameTitle: "",
                   imageUrl: "",
                   labUrlList: [],
                   gameIntro: "",
                   lastTime: "",
                   gameUpdates: "",
                   downPack: "",
                   dialogVisible: false,
                   priveShow: false,
                   loginState:false,
                   imgWidth: false,
                   empty: true,
                   downPacks:[],
                   activeSlide: 0,  //当前激活的滑块索引
                   install: false,   //控制安装按钮
                   playOnline: false, //在线玩
                   openiv: false,
                   intro: [
                        {
                            url:'https://file.handzonegame.com/web/guide_1_v2.png'
                        },
                        {
                            url:'https://file.handzonegame.com/web/guide_2.png'
                        },
                        {
                            url:'https://file.handzonegame.com/web/guide_3.png'
                        },
                        {
                            url:'https://file.handzonegame.com/web/guide_4.png'
                        },
                        {
                            url:'https://file.handzonegame.com/web/guide_5.png'
                        },
                        {
                            url:'https://file.handzonegame.com/web/guide_6.png'
                        }
                   ]
              }
           });

           onMounted(() => {
               //初始化获取游戏落地页配置
               gameLdyShow();
               //初始化
               alreadyLogged();
           });
           //获取游戏配置信息
           const gameLdyShow = async ()=>{
               var id  = route.query.id;
               // 获取当前页面的完整URL
               var currentUrl = window.location.href;
               //加入到url中
               if(!id){
                    // 国家列表        
                   let result = await axios.get(
                        "/data/iap/sitemap.json?v=13"
                    );
              
                    
                    var urls =  currentUrl.split("/");
         
                    var  bakName = urls[urls.length-1];
         
                  // 根据包名获取id
                   for (const item of result.data) {
                        if (item.packageName == bakName) {
                            id = item.id;
                            break;
                        }
                    }
               } 
            
                let  result = await ldyConfig({
                    id: id,
                    lang: route.query.hl ?  route.query.hl : store.state.app.lang
                });
                if(result){
                    //获取游戏中的分销开关
                    if(!result.data.id){
                        //没有配置落地页
                        state.data.empty = false;
                        return;
                    }
                    if(result.data.locked == 1 ){
                        //跳转到404页面
                        goTo("/404",{})
                        return;
                    }
                    //获取url上参数，根据推广者id获取信息
                    if(route.query.recommend){ //存在分销参数的
                        let res  =  await gainAccount(route.query.recommend);
                        if(res.locked && res.locked == 1){
                            //跳转到404页面
                            //window.location.href = 'https://play.handzonegame.com/404.html';
                            goTo("/404",{})
                            return;
                        }
                    }
        
                    state.data.gameTitle = result.data.gameTitle;
                    state.data.imageUrl = result.data.imageUrl;
                    state.data.labUrlList = result.data.labUrlList;
                    state.data.gameIntro = result.data.gameIntro;
                    state.data.lastTime = formatDateTime(new Date(result.data.lastTime),'yyyy年MM月dd日');
                    state.data.gameUpdates = result.data.gameUpdates;
                    state.data.downPacks = result.data.downPacks;
                    alterTitle();
                    //判断图片宽度是否统一
                    if(result.data.labUrlList){
                        var wid =  result.data.labUrlList[0].width;
                        for(var i = 0; i < result.data.labUrlList.length;i++){
                            if(wid ==  result.data.labUrlList[i].width){
                                continue;
                            }
                            state.data.imgWidth = true;
                        }   
                    }
                    //判断是否开启安装
                    isShowInstall();
                    //在线玩
                    isOnLine();

                    // 获取head标签中的meta标签
                    var metaTags = document.head.getElementsByTagName("meta");
                    // 遍历meta标签
                    for (var l = 0; l < metaTags.length; l++) {
                        // 获取name为description的meta标签中的content属性值
                        if (metaTags[l].name === "description") {
                            //var description = metaTags[i].content;
                            metaTags[l].content = result.data.desc.desc;
                        }
                        // 获取name为keywords的meta标签中的content属性值
                        if (metaTags[l].name === "keywords") {
                            metaTags[l].content = result.data.desc.keywords;
                        }
                    }  
                }
        }
           //时间格式化
           const formatDateTime = (date, format)=>{
               const o = {
                   'M+': date.getMonth() + 1, // 月份
                   'd+': date.getDate(), // 日
                   'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12, // 小时
                   'H+': date.getHours(), // 小时
                   'm+': date.getMinutes(), // 分
                   's+': date.getSeconds(), // 秒
                   'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
                   S: date.getMilliseconds(), // 毫秒
                   a: date.getHours() < 12 ? '上午' : '下午', // 上午/下午
                   A: date.getHours() < 12 ? 'AM' : 'PM', // AM/PM
                };
                if (/(y+)/.test(format)) {
                    format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
                }
                for (let k in o) {
                    if (new RegExp('(' + k + ')').test(format)) {
                       format = format.replace(
                           RegExp.$1,
                           RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
                       );
                    }
                }
                return format;
           }
           //安卓apk方法
           const installApk = ()=>{
               const isLogin =  store.getters.isLogin;
               if(isLogin){
                   if(!state.data.downPacks){
                       Toast.loading('没有配置安装包！！！');
                       return;
                   }
                   var ty = gainSys();
                   //根据系统调用不同安装包
                   for(var i = 0;i< state.data.downPacks.length;i++){
                        if(typeof(state.data.downPacks[i].type) != "undefined"  ){
                            if(ty == state.data.downPacks[i].type){
                               if(ty == "ios"){
                                    //显示安装提示
                                    state.data.openiv = true;
                                    mySwipe.value.swipeTo(0)
                               }
                               if(ty == "android"){
                                    window.location = state.data.downPacks[i].url ;
                               }
                   
                               return;
                            }
                        }
                   }
   
               }else{
                   store.commit("setNeedLogin", true); 
               }
           }
           //跳转页面
           const goTo = (url, params) => {
               router.push({ path: url, query: params})
           }
           //在线玩
           const playOnline = ()=>{
               const isLogin =  store.getters.isLogin;
               if(isLogin){
                   goTo("/gameplay/",{
                       id:  route.query.id,
                       url: state.data.downPacks[1].url
                   })
               }else{
                   store.commit("setNeedLogin", true); 
               }
           }
           //弹框控制
           const gameIntrodu = ()=>{
               state.data.dialogVisible = true;
           }
           //隐私政策
           const privacyPolicy =()=>{
               state.data.priveShow = true;
           }
           //判断是否已登录
           const alreadyLogged = ()=>{
               const isLogin =  store.getters.isLogin;
               if(isLogin){
                  state.data.loginState = false;
               }else{
                   state.data.loginState = true;
               }
           }
           //拉起登录弹框
           const login = ()=>{
               store.commit("setNeedLogin", true); 
           }
        
           //获取系统类型
           const gainSys = ()=>{
                var u = navigator.userAgent;
                var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
                var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                if (isAndroid) {
                    //这个是安卓操作系统
                    return 'android';
                }
                if (isIOS) {
                    //这个是ios操作系统
                    return 'ios';
                }
                return 'h5';
           }
           //动态设置轮播图的宽度
           const setImgWidth = (width)=>{
                if(width <=  400){
                    var y = Math.floor(width / 3); //取模，显示不能超过这个数 width / 3
                    return "width:"+  ((   y * 2 ) + 2) +"px;margin-right: 10px;";
                }

                //判断尺寸大于500的然后数组是否统一了宽度，如果不统一那么图片之间需要加一个边距来区分
                if(state.data.imgWidth){
                   return "margin-right: 10px;";
                }
                return '';
           }
           //监听轮播跳转
           const changeSwipe = (index)=>{
                var  s =  document.getElementsByClassName("van-swipe-item");
    
                var cou = [];
                for(var i = 0; i < s.length;i++){
                    let result = s[i].style.width.replace(/[^0-9]/g,"");
                    // 使用querySelector获取特定的子元素
                    cou.push(Number(result))
                }
              //  var mar = Math.max.apply("",cou); //最大的值
                var tra = document.getElementsByClassName("van-swipe__track");
                var mar =   tra[0].offsetWidth /s.length ;  //最大的值
                const sum = cou.reduce((accumulator, currentValue) => accumulator + currentValue, 0); //计算总和
                var y = Math.floor(sum / mar); //取模，显示不能超过这个数

                var sd =   y == 1 ? 2 : y;
                if( index >= sd){
                    state.data.activeSlide = 0;
                }else{
                    state.data.activeSlide = index;
                }
           }
           //判断是否展示安装按钮
           const isShowInstall = ()=>{
                //获取系统
                var ty = gainSys();
   
                //根据系统调用不同安装包
                for(var i = 0;i< state.data.downPacks.length;i++){
                    if(typeof(state.data.downPacks[i].type) != "undefined"  ){
                        if(ty == state.data.downPacks[i].type){
                            //判断安装包是否存在
                            if(state.data.downPacks[i].url && state.data.downPacks[i].url != '' ){
                                state.data.install = true;
                            }
                        }
                    }
                }
           }
           //在线玩
           const isOnLine = ()=>{
                //根据系统调用不同安装包
                for(var i = 0;i< state.data.downPacks.length;i++){
                    if(typeof(state.data.downPacks[i].type) != "undefined"  ){
                        if(state.data.downPacks[i].type == 'h5'){
                            //判断安装包是否存在
                            if(state.data.downPacks[i].url && state.data.downPacks[i].url != '' ){
                                state.data.playOnline = true;
                            }
                        }
                    }
                }
           }
           //跳转用户中心
           const skipUserCenter = ()=>{
                goTo("/my",{});
           }
           //页面标题更改
           const alterTitle = ()=>{
                //更改页面标题
                var pageTitle = document.getElementsByTagName('title')[0];
                pageTitle.text = state.data.gameTitle;
           }
           const closeInstall = () => {
                state.data.openiv = false;
           }
           //ios安装
           const ubuntu = () =>{
                for(var i = 0;i< state.data.downPacks.length;i++){
                    if(typeof(state.data.downPacks[i].type) != "undefined"  ){
                        if(state.data.downPacks[i].type == "ios"){
                            //显示安装提示
                            window.location.href = state.data.downPacks[i].url;
                            return;
                        }
                    }
                }       
           }
           //请求后台判断分销页面是否关闭，关闭后需跳转其他页面
           return{
               ...toRefs(state),
               installApk,
               playOnline,
               gameIntrodu,
               privacyPolicy,
               login,
               setImgWidth,
               changeSwipe,
               skipUserCenter,
               closeInstall,
               ubuntu,
               mySwipe
           }
       },
       components:{
           NavBar,
       }
   }
</script>
<style>
   .fFmL2e {
       border-radius: 20%;
       border-width: 0;
       box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
       margin: 7px 24px 0 0;
       width: 2rem;
   }
   .Fd93Bb {
       width: auto;
   }
   .Fd93Bb h1 {
       font-family: "Google Sans", Roboto, Arial, sans-serif;
       margin: 0px;
       word-break: keep-all;
       letter-spacing: 0;
       font-weight: 500;
       line-height: 0.7rem;
       font-size: 0.5rem;
       text-align: left;
       word-wrap: break-word;

       -webkit-box-orient: vertical;
       -webkit-line-clamp: 3;
       display: -webkit-box;
       overflow: hidden;
      /* font-weight: bold;*/
   }
   .u4ICaf {
       width: 160px;
       height: 36px;
       margin-right: 24px;
       border-radius: 8px;
       text-align: center;
       line-height: 36px;
       vertical-align: middle;
       background-color: #056449;
       font-size: 0.4rem;
   }
   .my-swipe .van-swipe-item {
       color: #fff;

        width: 100%;
       text-align: center;
       left: 0px;
       right: 0px;
   }

   .VMq4uf h2{
       font-family: "Google Sans", Roboto, Arial, sans-serif;
       line-height: 0.75rem;
       font-size: 0.5rem;
       letter-spacing: 0;
       text-align: left;
   }
   .bARER {
       display: -webkit-box;
       -webkit-box-orient: vertical;
       -webkit-line-clamp: 6;
       max-height: 9.8rem;
       overflow: hidden;
       color: rgb(95, 99, 104);
       font-size: 0.3rem;
       line-height: 0.5rem;
       text-align: left;
   }
   .material-icons {
       font-family: 'Material Icons';
       font-weight: normal;
       font-style: normal;
       font-size: 24px;
       line-height: 1;
       letter-spacing: normal;
       text-transform: none;
       display: inline-block;
       white-space: nowrap;
       word-wrap: normal;
       direction: ltr;
       text-rendering: optimizeLegibility;
       -webkit-font-smoothing: antialiased;
   }
   .KvNvKe {
       color: rgb(95, 99, 104);
       margin-right: 32px;
       line-height: 34px;
       font-size: 0.26rem;
   }
   .AJ34ce {
       display: grid;
       grid-template-columns: repeat(2, max-content);
       grid-template-rows: 1fr;
       column-gap: 12px;
   }
   .GrpFfc {
       align-self: center;
       width: 20px;
       height: 15px;
   }
   .xzVNx {
       color: rgb(32, 33, 36);
       fill: rgb(32, 33, 36);
       stop-color: rgb(32, 33, 36);
       line-height: 0.6rem;
       font-size: 0.4rem;
       letter-spacing: 0;
       font-weight: 500;
       -webkit-box-orient: vertical;
       -webkit-line-clamp: 1;
       display: -webkit-box;
       overflow: hidden;
       font-weight:bold;
   }
   .fysCi {
       line-height: 0.5rem;
       flex-grow: 1;
       /* margin: 0 32px; */
        /* overflow: scroll; */
       padding-bottom: 28px;
       font-size: 0.3rem;
       text-align: left;
   }
   .r7tDJb {
      
       border-top: 1px solid;
       margin-top: 24px;
       width: 100%;
   }
   .r7tDJb:not(:disabled) {
        border-color: rgb(232, 234, 237);
   }
   .G1zzid {
       border-color: rgb(232, 234, 237);
       border-bottom: 1px solid;
       display: flex;
       flex-wrap: wrap;
       margin-bottom: 24px;
       padding-bottom: 20px;
       text-align: left;
   }
   .G1zzid:not(:disabled) {
        border-color: rgb(232, 234, 237);
   }
   .sMUprd {
       flex-basis: 50%;
       margin-top: 24px;
   }
   .q078ud {
       color: rgb(32, 33, 36);
       fill: rgb(32, 33, 36);
       stop-color: rgb(32, 33, 36);
       font-family: "Google Sans", Roboto, Arial, sans-serif;
       line-height: 0.4rem;
       font-size: 0.3rem;
       letter-spacing: .0178571429em;
       font-weight: 500;
   }
   .reAt0 {
       color: rgb(95, 99, 104);
       fill: rgb(95, 99, 104);
       stop-color: rgb(95, 99, 104);
       font-size: 0.28rem;
       font-weight: 400;
       letter-spacing: .0178571429em;

       margin-top: 3px;
   }
   .dialogDesc{
       --van-dialog-border-radius:0px;
       max-width: 90%;
   }
   .custom-title{
       display: flex;
       text-align: left;
       margin-top: -18px;
       margin-left: 20px;
   }
   .custom-title-close{
       margin-top: -8px;
       margin-right: 3px;
       text-align: right;
   }
   .header-wrap {
       position: fixed;
       z-index: 998;
       top: 0;
       left: 0;
       width: 100%;
     /*  -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
   box-shadow: 0 1px 2px 0 rgba(0,0,0,.05); */
      /*   box-shadow: 0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12),0 2px 4px -1px rgba(0,0,0,.2);*/
       background: rgb(248, 248, 248);
       max-width: 15.45894rem;
       right: 0;
       margin: 0 auto;
   }
   .header {
       width: 100%;
  
       font-size: 16px;
       user-select: none;
       position: relative;
   }
   .header-main {
       display: -webkit-box;
       display: -ms-flexbox;
       display: flex;
       -webkit-box-align: center;
       -ms-flex-align: center;
       align-items: center;
       height: 50px;
       justify-content: space-between;
   }
   .header-left {
       position: relative;
       height: 100%;
       color: #51a7e0;
       font-size: 18px;
       font-weight: 500;
       line-height: 26px;
     /*  align-items: center;*/
       display: flex;
       margin-left: 12px;
   }
   .header-main img {
      /* width: 4.3rem;*/
       height: 35px; 
       vertical-align: middle;
   }
   .header-right {
       display: flex;
       position: relative;
       align-items: center;
       height: 100%;
   }
   .header-user {
       height: -webkit-fit-content;
       height: -moz-fit-content;
       height: fit-content;
       -webkit-box-orient: vertical;
       -webkit-box-direction: normal;
       -ms-flex-direction: column;
       flex-direction: column;
      /* padding: 6px 6px 0;*/
       color: #5c5d61;
      /* background-color: #fff;*/
       font-size: 14px;
       cursor: pointer;
       display: flex;
       position: relative;
       margin-right: 10px;
   }
   .i-button__primary {
       background-color: #00c3ff;
       color: #fff;
       border: 1px solid #00c3ff;
   }
   .header__login {
       width: 59px;
       height: 34px;
       line-height: 34px;
   }
   .i-button {
       display: -webkit-box;
       display: -ms-flexbox;
       display: flex;
       -webkit-box-align: center;
       -ms-flex-align: center;
       align-items: center;
       -webkit-box-pack: center;
       -ms-flex-pack: center;
       justify-content: center;
       border-radius: 12px;
       font-style: normal;
       font-weight: 500;
       letter-spacing: .1px;
       -webkit-box-sizing: border-box;
       box-sizing: border-box;
       white-space: nowrap;
   }

   .VMq4uf-desc{
       font-size: 0.3rem;
       text-align: left;
       line-height: 0.5rem;
       margin-top: 20px;
       color: rgb(95, 99, 104);
   }

       /* 两个按钮共有的样式,也可直接使用箭头图片替代 */
       .leftBtn,
       .rightBtn {
           position: absolute;
           top: 50%;
           transform: translateY(-50%);
           width: 30px;
           height: 30px;
           display: flex;
           justify-content: center;
           align-items: center;
           background: rgba(109, 109, 109, 0.445);
           color: #fff;
           border-radius: 50%;
           cursor: pointer;
           font-size: 12px;
           font-weight: 500;
           user-select: none;
       }
       .leftBtn {
           left: 10px;
       }
       .rightBtn {
           right: 10px;
       }
       /* 下方指示器盒子 */
       .instBox {
           position: absolute;
           left: 50%;
           transform: translateX(-50%);
           bottom: 10px;
           display: flex;
       }
       /* 小圆点 */
       .inst {
           width: 10px;
           height: 10px;
           border: 1px solid #ccc;
           margin-right: 8px;
           background: #fff;
           border-radius: 50%;
           cursor: pointer;
       }
       .inst:last-child {
           margin-right: 0px;
       }
       .instActv {
           border: 1px solid #ff0000;
           background: #ff0000;
       }
       .google-material-icons {
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            color: rgb(95, 99, 104);
            -webkit-font-smoothing: antialiased;
        }
    @media screen and (min-width: 640px){
        .Fd93Bb{
           width: 91%;
        }
       .Fd93Bb h1 {
          line-height: 0.9rem;
          font-size: 0.6rem;
      }
      .fFmL2e{
       width: 2.6rem;
      }
      .bARER{
           font-size: 0.4rem;
           line-height: 0.6rem;
      }
      .KvNvKe {

           font-size: 0.32rem;
       }
       .xzVNx{
           font-size: 0.6rem;
       }
       .dialogDesc {
           font-size: 0.6rem;
       }
       .fysCi{
           font-size: 0.42rem;
           line-height: 0.7rem;
       }
       .q078ud{
           font-size: 0.42rem;
       }
       .reAt0{
           font-size: 0.42rem;
       }
       .custom-title{
           margin-top: 0px;
       }
       .custom-title-close{
           margin-top: -15px;
           margin-right: 3px;
       }
      /* .header-main {
           height: 50px;
       }*/
       .VMq4uf-desc{
           font-size: 0.4rem;
           line-height: 0.7rem;
       }
       .VMq4uf h2{
           font-size: 0.6rem;
           font-weight: 500;
       }
   }
</style>