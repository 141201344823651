import axios from '../utils/axios'

export function requestBanner() {
    return axios.get('/web/banner')
}

export function requestGameList(test,gameId) {
    return axios.get('/web/gameList?test='+test+'&gameId='+gameId)
}

export function requestInfo(id) {
    return axios.get(`/web/game/${id}`)
}

export function gameConfig(id){
    return axios.get(`/game/${id}`)
}

export function ldyConfig(params){
    return axios.post('/land/show/', params)
}

export function gainAccount(id){
    return axios.get(`fee/gainAccount/${id}`)
}

export function packname(id){
    return axios.get(`land/packname/${id}`)
}

export function getGameMone(params){
    return axios.post('/land/getGameMone/', params)
}
