<template>
  <div>
      <router-view class="router"/>
      <login v-if="loginVisible"></login>
  </div>
  
</template>

<script>
import { computed, reactive, toRefs, onMounted  } from 'vue'
import { useStore } from 'vuex'
import { getStore,clearKey } from '@/utils/store'
import Login from '@/components/Login'
import { validatenull } from '@/utils/validate';
import Fingerprint2 from 'fingerprintjs2'
import { reqestDevice } from "@/service/user";
import { gameConfig } from "@/service/web"; 
export default {
    metaInfo:{
      title: '掌域遊戲-H5遊戲線上玩_好玩的手機網頁遊戲排行榜大全_推薦熱門遊戲不用下載電腦版',
      meta: [
          {
            name: 'description',
            content: '掌域遊戲，為玩家提供超好玩的的手機網頁遊戲，本站提供的所有H5遊戲、手機頁遊都可以直接線上免費玩，不用下載APP應用，支持安卓蘋果所有手機，H5遊戲大全盡在掌域遊戲',
          },
          {
            name: 'keywords',
            content: '放置傳奇挂機王者手游,傳世暗黑屠龍榮耀霸業戰歌傳說,少年夢幻口袋回合亂鬥,大話全民悟空開心西遊'
          }
      ]
    },
    setup() {
        const store = useStore()
        const state = reactive({
        })

        const loginVisible = computed (()=> {
            if (store.getters.needLogin) {
                let storeToken = validatenull(getStore({ name: 'token' }))
                if (!store.getters.isLogin || validatenull(store.getters.token) || validatenull(storeToken)) {
                    return true
                }
            }
            return false
        })
        
        onMounted(() => {
   
            let deviceId = store.getters.deviceId;
            if (validatenull(deviceId)) {
                Fingerprint2.get(function(components) {
                    const values = components.map(function(component, index) {
                      if (index === 0) { //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
                        return component.value.replace(/\bNetType\/\w+\b/, '')
                      }
                      return component.value
                    })
                    // 生成最终id murmur
                    deviceId = Fingerprint2.x64hash128(values.join(''), 31)
                    store.commit("setDeviceId", deviceId)
                    var params = {
                        "gameId": store.getters.appId,
                        "deviceId": deviceId,
                        "ssid": "",
                        "channel": store.getters.channel,
                    };
                    reqestDevice(params);
                })
            }

            // 在这里添加 预渲染事件
            document.dispatchEvent(new Event('render-event'))

            let vh = window.innerHeight * 0.01
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`)

            // We listen to the resize event
            window.addEventListener('resize', () => {
              // We execute the same script as before
              let vh = window.innerHeight * 0.01
              console.log(vh);
              document.documentElement.style.setProperty('--vh', `${vh}px`)
            })

            initialize();

            versionInitia();
        })


        const initialize =  async ()=>{
            //初始化获取游戏的一些参数
            const result = await gameConfig(store.getters.appId);
            //缓存登录方式
            if(result.gameLoginType){
              //缓存登录方式
              store.dispatch("gameLoginType", result)
            }else{
              store.dispatch("gameLoginType", null)
            }
        }

        //初始化版本，版本变动需清空指定缓存信息
        const versionInitia = async ()=>{
           var currentVersion = 'v1.12';
           //获取缓存中的版本号
           var version = store.state.app.version;
           
           if(!version){
              //缓存当前版本号
              store.commit("setVersion", currentVersion);
              //清空缓存
              clearKey("test");
              clearKey("gameId");
              return;
           }

           if(currentVersion != version){
              //刷新缓存，清除掉指定缓存信息
              store.commit("setVersion", currentVersion);
              //清空缓存
              clearKey("test");
              clearKey("gameId");
           }
        } 

        return {
          ...toRefs(state),
          loginVisible
        }
    },
    components: {
        Login
    }
}
</script>

<style lang="less">
body {
  background: url() center 51px / cover no-repeat rgb(0, 0, 0);
  overflow: hidden;
  overflow-y: hidden;
}

#app {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  font-family: Microsoft YaHei,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Heiti SC,WenQuanYi Micro Hei,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #F7F8FA;
  line-height: normal;
  align-content: center;
  max-height: 100%;
}

@media screen and (min-width: 640px) {
  html {
    font-size: 90px !important; // 这里加权重是因为 amfe-flexible 通过行内样式设置了根字体
    margin: 0 auto;
  }
  #app {
    max-width: 640px; // 顺带提下，这里也可以小写 px，大写 PX 可以让 postcss-pxtorem 忽略转成 rem
    margin: 0 auto;
    padding: 10px;
    border: 3px solid rgb(153, 153, 153);
    border-radius: 20px;
  }
}
.google-material-icons {
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            color: rgb(95, 99, 104);
            -webkit-font-smoothing: antialiased;
        }
  @media screen and (min-width: 640px) {
    .game-list {
      li {
          .txt {
              width: 500px;
          }
          .button-start,
          .button {
              width: 70px;
          }
      }
    }
}
@media (min-width: 371px) and (max-width: 660px) {

    .van-tabs {
        margin-left: 3px;
        width: 98%;
    }
}

</style>
 